@import "../../../styles/mixins";

.image {
  $self: &;

  &--logo-color-blue {
    color: var(--text-brand);
  }

  &--logo-color-dark {
    color: var(--text-heading);
  }

  &--full-width {
    position: relative;
    aspect-ratio: 1440 / 640;

    #{ $self }__inner {
      padding: 0;
    }

    #{ $self }__image {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
    }
  }

  &--default-width {
    #{ $self }__image {
      aspect-ratio: 1280 / 640;
      border-radius: var(--radius-xl);
    }
  }

  &__inner {
    padding: gap(20) 0;
  }

  &__image {
    width: 100%;
    object-fit: cover;
  }

  &__svg {
    display: none;

    @include media(lg) {
      display: block;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
}