@import "../../../styles/mixins";

.b-text-image {
  $self: &;

  background-color: var(--color-white-100);
  position: relative;

  &--color-dark {
    background-color: var(--section-neutral-dark);
    color: var(--color-white-100);

    #{ $self }__wave {
      color: var(--bg-brand-dark) !important;
    }
  }

  &--color-light-blue {
    background-color: var(--bg-brand-light);

    #{ $self }__wave {
      color: var(--color-white-100) !important;
    }
  }

  &--type-card-image {
    padding: gap(10) 0;

    @include media(lg) {
      padding: gap(20) 0;
    }

    #{ $self }__content {
      position: relative;
    }

    #{ $self }__thumb {
      @include media(lg) {
        border-radius: gap(4);
      }
    }
  }

  &--type-full-image {
    #{ $self }__content {
      @include media(lg) {
        grid-gap: 0;
      }

      &--position-right {
        #{ $self }__info {
          @include media(lg) {
            order: 2;
          }
        }

        #{ $self }__thumb-container {
          @include media(lg) {
            transform: translateX(gap(-20));
          }
        }
      }
    }

    #{ $self }__info {
      padding: gap(4) 0;
    }

    #{ $self }__thumb-container {
      @include media(lg) {
        padding: 0;
        transform: translateX(gap(20));
      }
    }
  }

  &--type-small-image {
    #{ $self }__info {
      padding: gap(8) 0;
    }

    #{ $self }__thumb-container {
      padding: 0;

      @include media(lg) {
        margin: 0;
        transform: translateX(gap(20));
      }
    }

    #{ $self }__thumb {
      aspect-ratio: 640 / 400;
    }

    #{ $self }__content {
      &--position-right {
        #{ $self }__info {
          @include media(lg) {
            order: 2;
          }
        }

        #{ $self }__thumb-container {
          @include media(lg) {
            transform: translateX(gap(-20));
          }
        }
      }
    }
  }

  &__content {
    @include grid(1, 1, 2, 2, 2);
    grid-gap: gap(8);
    z-index: 1;

    @include media(lg) {
      grid-gap: gap(20);
    }

    &--position-right {
      #{ $self }__info {
        @include media(lg) {
          order: 2;
        }
      }
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: gap(4);
    align-self: center;
    position: relative;
    z-index: 1;
  }

  &__thumb-container {
    align-self: center;
    margin: 0 gap(-4);

    @include media(lg) {
      padding: 0 gap(15);
      margin: 0;
    }
  }

  &__thumb {
    overflow: hidden;
    width: 100%;
    object-fit: cover;
    aspect-ratio: 375 / 264;

    @include media(lg) {
      aspect-ratio: 1 / 1;
    }
  }

  &__buttons {
    padding-top: gap(2);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: gap(4);
  }

  &__wave {
    color: var(--bg-brand-light) !important;

    @include media(lg, max) {
      display: none !important;
    }
  }
}
