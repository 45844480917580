@import "../../../styles/mixins";

.list-item {
  display: inline-flex;
  gap: gap(2);
  color: var(--text-primary);

  &__text {
    padding: gap() 0;
  }
}
