@import "../../../styles/mixins";

.marquee {
  $self: &;
  width: 100%;
  overflow: hidden;

  &:hover {
    #{ $self }__group {
      animation-play-state: paused;
    }
  }

  &--reverse {
    #{ $self }__group {
      animation-direction: reverse;
    }
  }

  &__container {
    display: flex;
    gap: var(--marquee-gap);
  }

  &__group {
    animation: scroll-y var(--marquee-duration) linear infinite;
  }
}

@keyframes scroll-y {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - var(--marquee-gap)));
  }
}