@import "../../../styles/mixins";

.image-gallery {
  $self: &;

  background-color: var(--color-white-100);
  color: var(--color-grey-900);
  padding: gap(8) 0;
  overflow: hidden;

  @include media(lg) {
    padding: gap(20) 0;
  }

  &--color-dark {
    background-color: var(--section-neutral-dark);
    color: var(--color-white-100);
  }

  &--color-blue {
    background-color: var(--section-brand-light);
  }

  &__content {
    @include grid(1, 1, 2, 2, 2);
    grid-gap: gap(6) gap(10);
  }

  &__image {
    width: 100%;
    aspect-ratio: 602/450;
    border-radius: gap(4);
    overflow: hidden;
    object-fit: cover;
  }

  &__slider {
    overflow: visible;
  }

  &__slide {
    width: calc(100% - 80px);
  }
}
