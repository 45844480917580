.tag {
  $self: &;

  display: inline-flex;
  align-items: center;
  gap: 4px;
  border-radius: var(--spacing-99);
  padding: 0 var(--atom-sm);
  height: var(--spacing-6);
  background-color: var(--bg-neutral-light);
  color: var(--text-primary);
  outline: 1px solid var(--border-primary);

  &__icon {
    color: currentColor;
  }

  &.neutral {
    #{ $self }__icon {
      color: var(--icon-primary);
    }

    background-color: var(--color-white-100);

    &:not(.light) {
      background-color: var(--bg-neutral-light-inverted);
      color: var(--text-primary-inverted);
      outline-color: var(--border-primary-inverted);
    }
  }

  &.dark {
    outline: none;
    background-color: var(--bg-neutral-dark);
    color: var(--text-primary-inverted);
  }

  &.brand-light {
    outline: none;
    background-color: var(--bg-brand-light);

    #{ $self }__icon {
      color: var(--icon-primary);
    }
  }

  &.brand-dark {
    outline: none;
    background-color: var(--bg-brand-dark);
    color: var(--text-primary-inverted);

    &:not(.light) {
      background-color: var(--bg-brand-dark-inverted);
      color: var(--text-brand-1);
    }
  }

  &.red {
    outline: none;
    background-color: var(--bg-error);
    color: var(--text-error);
  }

  &.green {
    outline: none;
    background-color: var(--bg-success);
    color: var(--text-success);
  }

  &.orange {
    outline: none;
    background-color: var(--bg-warning);
    color: var(--text-warning);
  }

  &.blue {
    outline: none;
    background-color: var(--bg-info);
    color: var(--text-info);
  }
}
