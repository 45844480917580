@import "../../../styles/mixins";

.b-relevant-brands {
  background-color: var(--bg-neutral-light);
  padding: gap(8) 0;
  overflow: hidden;
  position: relative;

  @include media(lg) {
    padding: gap(20) 0;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: gap(8);
  }

  &__head {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: gap(157);
    gap: gap(4);
    color: var(--text-primary);
    text-align: center;
  }

  &__list {
    @include grid(1, 1, 3, 3, 3);
    grid-gap: gap(8) gap(4);
    position: relative;
    z-index: 1;

    @include media(lg, max) {
      display: none;
    }
  }

  &__slider {
    overflow: visible;
    width: 100%;
    position: relative;
    z-index: 1;
    
    @include media(lg) {
      display: none;
    }
  }

  &__slide {
    width: calc(100% - 80px);
  }

  &__wave {
    color: var(--section-neutral-dark) !important;
  }
}
