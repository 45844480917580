@import "../../../styles/mixins";

.b-tabs {
  background-color: var(--color-white-100);
  padding: gap(8) 0;

  @include media(lg) {
    padding: gap(16) 0;
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: gap(8);

    @include media(lg) {
      gap: gap(10);
    }
  }

  &__content {
    border-radius: gap(4);

    @include media(lg) {
      border: 1px solid var(--border-primary);
      box-shadow: var(--shadow-500);
      overflow: hidden;
    }
  }

  &__tabs {
    display: flex;
    overflow-x: scroll;

    @include media(lg, max) {
      margin: 0 gap(-4);
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__tabs-content {
    @include media(lg, max) {
      margin-top: gap(8);
      border-radius: gap(4);
      box-shadow: var(--shadow-500);
      border: 1px solid var(--border-primary);
      overflow: hidden;
    }
  }

  &__tab {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;
    position: relative;
    border-bottom: 1px solid var(--border-primary);
    color: var(--text-heading);
    padding: gap() gap(2) gap(4) gap(2);

    @include media(lg) {
      flex: 1;
      padding: 0;
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      bottom: -1px;
      width: 100%;
      height: 0;
      background-color: var(--border-brand-1);
      transition: var(--transition-all);
      opacity: 0;
    }

    &.active {
      color: var(--text-primary);
      font-weight: 900 !important;

      &::before {
        height: 3px;
        opacity: 1;

        @include media(lg) {
          height: 7px;
        }
      }
    }
  }

  &__tab-title {
    @include media(lg) {
      padding: 0 gap(6);
    }
  }

  &__tab-thumb {
    width: gap(20);
    min-width: gap(20);
    aspect-ratio: 1 / 1;
    object-fit: cover;

    @include media(lg, max) {
      display: none;
    }
  }

  &__tab-content {
    @include grid(1, 1, 2, 2, 2);
  }

  &__tab-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    aspect-ratio: 343 / 300;

    @include media(lg) {
      aspect-ratio: 640 / 308;
    }
  }

  &__tab-content-info {
    display: flex;
    flex-direction: column;
    gap: gap(6);
    padding: gap(8) gap(4);
    color: var(--text-primary);

    @include media(lg) {
      padding: gap(10) gap(16);
      align-self: center;
    }
  }

  &__tab-content-description {
    display: flex;
    flex-direction: column;
    gap: gap(6);
  }

  &__tab-content-list {
    display: flex;
    flex-direction: column;
    gap: gap(2);
  }

  &__tab-content-links {
    @include grid(1, 1, 2, 2, 2);
    grid-gap: gap(2);
  }
}
