@import "../../../styles/mixins";

.header {
  top: 0;
  position: sticky;
  background-color: var(--color-white-100);
  box-shadow: var(--shadow-300);
  z-index: 999999;

  @include media(lg) {
    top: var(--header-sticky-top);
  }

  &__desktop {
    display: none;

    @include media(lg) {
      display: block;
    }
  }

  &__mobile {
    @include media(lg) {
      display: none;
    }
  }
}