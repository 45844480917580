@import "../../../styles/mixins";

.input {
  $self: &;
  position: relative;
  border-radius: var(--radius-sm);
  border: 1px solid var(--border-primary);
  background-color: var(--bg-neutral-light);
  transition: var(--transition-border);

  &--disabled {
    background-color: var(--bg-disabled-1);
    border-color: var(--border-disabled) !important;

    #{ $self }__data-entry, #{ $self }__label {
      color: var(--text-disabled);
    }
  }

  &--error {
    border-color: var(--border-error);
  }

  &__data-entry {
    width: 100%;
    border: none;
    outline: none;
    color: var(--text-primary);
    background-color: transparent;
    padding: gap(6) gap(4) gap(2);
    border-radius: var(--radius-sm);

    &::placeholder {
      visibility: hidden;
      opacity: 0;
    }

    &:placeholder-shown {
      & ~ #{ $self }__label {
        top: 50%;
        font-size: 16px;
      }
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-text-fill-color: var(--text-primary);
      -webkit-box-shadow: 0 0 0 30px var(--color-white-100) inset !important;
    }
  }

  &:hover {
    border-color: var(--border-brand-1-hover);
  }

  &:focus-within {
    border-color: var(--border-brand-1-active);

    #{ $self }__label {
      top: gap(4);
      font-size: 10px;
    }
  }

  &__label {
    pointer-events: none;
    position: absolute;
    color: var(--text-placeholder);
    top: gap(4);
    left: gap(4);
    font-size: 10px;
    transform: translateY(-50%);
    transition: var(--transition-all);
  }

  &__error {
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    width: 100%;
    color: var(--text-error);
  }
}