@import "../../../styles/mixins";

.title-side {
  $self: &;
  overflow: hidden;
  position: relative;

  &--color-light {
    background-color: var(--section-neutral-light);

    #{ $self }__heading, #{ $self }__description {
      color: var(--text-primary);
    }

    #{ $self }__visual-wave, #{ $self }__visual-circle, {
      color: var(--bg-brand-light);
    }
  }

  &--color-dark {
    background-color: var(--section-neutral-dark);

    #{ $self }__heading, #{ $self }__description {
      color: var(--text-primary-inverted);
    }

    #{ $self }__visual-wave, #{ $self }__visual-circle {
      color: var(--bg-brand-dark);
    }
  }

  &--orientation-right {
    #{ $self }__content {
      @include media(lg) {
        flex-direction: row-reverse;
      }
    }
  }

  &__inner {
    padding: var(--block-top-bottom) 0;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: gap(2);

    @include media(lg) {
      flex-direction: row;
      position: relative;
      z-index: 5;
      gap: var(--block-xl);
    }

    &-left, &-right {
      @include media(lg) {
        flex-basis: 50%;
      }
    }

    &-right {
      display: flex;
      flex-direction: column;
      gap: gap(12);
    }
  }

  &__links {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: gap(4);
  }

  &__visual {
    &-circle {
      bottom: 0;
      display: none;
      position: absolute;

      &--orientation-left {
        left: 0;
      }

      &--orientation-right {
        right: gap(20);
      }

      @include media(lg) {
        display: block;
      }
    }

    &-wave {
      @include media(lg, max) {
        display: none !important;
      }
    }
  }
}