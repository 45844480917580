@import "../../../styles/mixins";

.b-content-slider {
  background-color: var(--bg-neutral-dark);
  padding: gap(8) 0;
  overflow: hidden;

  @include media(lg) {
    padding: gap(20) 0;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: gap(16);

    @include media(lg) {
      gap: gap(12);
    }
  }

  &__head {
    width: 100%;
    max-width: gap(157);
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: gap(4);
    color: var(--text-primary-inverted);
  }

  &__slider {
    position: relative;
    width: 100%;
    overflow: visible;
  }

  &__slider-slide {
    width: gap(62.5);
    height: auto;

    @include media(lg) {
      width: gap(80);
    }
  }

  &__slider-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 3;

    @include media(lg, max) {
      display: none;
    }

    &--prev {
      left: 0;
    }

    &--next {
      right: 0;
    }
  }
}
