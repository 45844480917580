@import "../../../../styles/mixins";

.c-content-grid {
  $self: &;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: gap(2);
  background-color: var(--color-white-100);
  box-shadow: var(--shadow-default);

  &--variant-news {
    color: var(--text-heading-inverted);
    background-color: var(--bg-brand-dark);

    #{ $self }__wave {
      color: var(--bg-brand-dark)
    }
  }

  &__inner {
    position: relative;
  }

  &__thumb {
    width: 100%;
    aspect-ratio: 404 / 304;
    object-fit: cover;
  }

  &__wave {
    width: 100%;
    height: auto;
    position: absolute;
    bottom: -2px;
    left: 0;
    color: var(--color-white-100);
  }

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: gap(4);
    justify-content: flex-end;
    padding: gap() gap(4) gap(4) gap(4);
  }

  &__title {
    @include line-clamp(2);
  }

  &__description {
    @include line-clamp(2);
  }

  &__date {
    opacity: 0.7;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: gap(4);
  }
}
