@import "../../../styles/mixins";

.content-cards {
  background-color: #D0E2FB;
  padding: var(--block-md) 0;
  position: relative;

  &--bg-white {
    background-color: var(--color-white-100);
  }

  @include media(lg) {
    padding: var(--block-top-bottom) 0;
  }

  &__container {
    @include grid(1, 1, 1, 1, 1);
    gap: gap(10);

    @include media(lg) {
      gap: gap(20);
    }
  }

  &__head {
    display: flex;
    flex-direction: column;
    gap: gap(4);
    justify-self: center;
    text-align: center;
    color: var(--text-primary);
    width: 100%;
    max-width: 628px;

    @include media(lg, max) {
      gap: gap(2);
    }
  }

  &__content {
    @include grid(1, 1, 2, 2, 2);
    gap: gap(4);
    position: relative;
    z-index: 1;

    &--more {
      @include grid(1, 1, 2, 3, 3);
    }

    @include media(lg) {
      gap: gap(8);
    }
  }
}
