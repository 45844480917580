@import "utils/media";

@function gap($base: 1) {
  @return $base * 4px;
}

@mixin media($breakpoint, $type: min) {
  @each $key, $size in $media {
    @if $breakpoint == $key {
      @if $type == min {
        @media screen and (min-width: $size) {
          @content;
        }
      } @else {
        @media screen and (max-width: $size - 1) {
          @content;
        }
      }
    }
  }
}

@mixin grid($sm: 1, $md: 1, $lg: 12, $xl: 12, $xxl: 12) {
  display: grid;
  grid-template-columns: repeat($sm, 1fr);

  @include media(sm) {
    grid-template-columns: repeat($md, 1fr);
  }

  @include media(lg) {
    grid-template-columns: repeat($lg, 1fr);
  }

  @include media(xl) {
    grid-template-columns: repeat($xl, 1fr);
  }

  @include media(xxl) {
    grid-template-columns: repeat($xxl, 1fr);
  }
}

@mixin line-clamp($lines: 2) {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
}
