
/** Container **/
.container {
  margin: 0 auto;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
  max-width: 1312px;
}
:root {
  --color-blue: #2F80ED;
  --color-black-0: #00000000;
  --color-black-10: #0000001a;
  --color-black-20: #00000033;
  --color-black-30: #0000004d;
  --color-black-40: #00000066;
  --color-black-50: #00000080;
  --color-black-60: #00000099;
  --color-black-70: #000000b2;
  --color-black-80: #000000cc;
  --color-black-90: #0000005e;
  --color-black-100: #000000;
  --color-brand-1-100: #d0e2fb;
  --color-brand-1-200: #b9d4f9;
  --color-brand-1-300: #8ab7f5;
  --color-brand-1-400: #5b9bf1;
  --color-brand-1-500: #2f80ed;
  --color-brand-1-600: #2667c0;
  --color-brand-1-700: #1e5095;
  --color-brand-1-800: #0a3875;
  --color-brand-1-900: #062246;
  --color-brand-2-100: #5d9fe0;
  --color-brand-2-200: #3286d7;
  --color-brand-2-300: #236cb3;
  --color-brand-2-400: #1a5289;
  --color-brand-2-500: #12385d;
  --color-brand-2-600: #0f2e4d;
  --color-brand-2-700: #0a1f33;
  --color-brand-2-800: #071522;
  --color-brand-2-900: #030a11;
  --color-grey-25: #fcfcfd;
  --color-grey-50: #f9fafb;
  --color-grey-100: #f2f4f7;
  --color-grey-200: #eaecf0;
  --color-grey-300: #d0d5dd;
  --color-grey-400: #98a2b3;
  --color-grey-500: #667085;
  --color-grey-600: #475467;
  --color-grey-700: #344054;
  --color-grey-800: #1d2939;
  --color-grey-900: #101828;
  --color-support-attention-100: #fdf6dd;
  --color-support-attention-300: #f9e59f;
  --color-support-attention-500: #917208;
  --color-support-error-100: #fcdfdf;
  --color-support-error-300: #f6a2a2;
  --color-support-error-500: #ba1212;
  --color-support-focus-100: ;
  --color-support-focus-300: #538dff;
  --color-support-focus-500: #275dc5;
  --color-support-information-100: #edf5ff;
  --color-support-information-300: #99c7ff;
  --color-support-information-500: #00408f;
  --color-support-success-100: #e0f9e8;
  --color-support-success-300: #aaeebe;
  --color-support-success-500: #21ab4b;
  --color-white-0: #ffffff00;
  --color-white-10: #ffffff1a;
  --color-white-20: #ffffff33;
  --color-white-30: #ffffff4d;
  --color-white-40: #ffffff66;
  --color-white-50: #ffffff80;
  --color-white-60: #ffffff99;
  --color-white-70: #ffffffb2;
  --color-white-80: #ffffffcc;
  --color-white-90: #ffffffe5;
  --color-white-100: #ffffff;
  --bg-backdrop: var(--color-black-60);
  --bg-brand-dark: var(--color-brand-1-500);
  --bg-brand-dark-active: var(--color-brand-1-900);
  --bg-brand-dark-hover: var(--color-brand-1-700);
  --bg-brand-dark-inverted: var(--color-brand-1-100);
  --bg-brand-dark-inverted-active: var(--color-white-60);
  --bg-brand-dark-inverted-hover: var(--color-white-80);
  --bg-brand-light: var(--color-brand-1-100);
  --bg-brand-light-active: var(--color-brand-1-300);
  --bg-brand-light-active-inverted: var(--color-white-60);
  --bg-brand-light-hover: var(--color-brand-1-200);
  --bg-brand-light-hover-inverted: var(--color-white-40);
  --bg-brand-light-inverted: var(--color-white-20);
  --bg-disabled-1: var(--color-grey-100);
  --bg-disabled-1-inverted: var(--color-white-10);
  --bg-surface-disabled-2: var(--color-grey-300);
  --bg-surface-disabled-2-inverted: var(--color-white-30);
  --bg-neutral-dark: var(--color-grey-900);
  --bg-neutral-dark-active: var(--color-grey-500);
  --bg-neutral-dark-hover: var(--color-grey-700);
  --bg-neutral-dark-inverted: var(--color-white-0);
  --bg-neutral-dark-inverted-active: var(--color-white-50);
  --bg-neutral-dark-inverted-hover: var(--color-white-70);
  --bg-neutral-light: var(--color-white-0);
  --bg-neutral-light-active: var(--color-grey-200);
  --bg-neutral-light-hover: var(--color-grey-100);
  --bg-neutral-light-inverted: var(--color-white-0);
  --bg-neutral-light-inverted-active: var(--color-white-40);
  --bg-neutral-light-inverted-hover: var(--color-white-20);
  --bg-error: var(--color-support-error-100);
  --bg-error-dark: var(--color-support-error-500);
  --bg-focus: var(--color-white-10);
  --bg-info: var(--color-support-information-100);
  --bg-info-dark: var(--color-support-information-500);
  --bg-neutral: var(--color-white-0);
  --bg-success: var(--color-support-success-100);
  --bg-success-dark: var(--color-support-success-500);
  --bg-warning: var(--color-support-attention-100);
  --bg-warning-dark: var(--color-support-attention-500);
  --bg-transparent-dark-10: var(--color-black-10);
  --bg-transparent-light-20: var(--color-white-20);
  --icon-disabled: var(--color-grey-400);
  --icon-disabled-inverted: var(--color-white-40);
  --icon-neutral: var(--color-black-100);
  --icon-neutral-active: var(--color-brand-1-500);
  --icon-neutral-hover: var(--color-black-80);
  --icon-neutral-inverted: var(--color-white-100);
  --icon-neutral-inverted-active: var(--color-white-100);
  --icon-neutral-inverted-hover: var(--color-white-80);
  --icon-primary: var(--color-brand-1-500);
  --icon-primary-active: var(--color-brand-1-900);
  --icon-primary-hover: var(--color-brand-1-700);
  --icon-primary-inverted: var(--color-white-100);
  --icon-primary-inverted-active: var(--color-white-40);
  --icon-primary-inverted-hover: var(--color-white-70);
  --icon-secondary: var(--color-grey-400);
  --icon-secondary-active: var(--color-grey-800);
  --icon-secondary-hover: var(--color-grey-600);
  --icon-secondary-inverted: var(--color-white-70);
  --icon-secondary-inverted-active: var(--color-white-30);
  --icon-secondary-inverted-hover: var(--color-white-50);
  --icon-error: var(--color-support-error-500);
  --icon-error-inverted: var(--color-support-error-100);
  --icon-info: var(--color-support-information-500);
  --icon-info-inverted: var(--color-support-information-100);
  --icon-success: var(--color-support-success-500);
  --icon-success-inverted: var(--color-support-success-100);
  --icon-warning: var(--color-support-attention-500);
  --icon-warning-inverted: var(--color-support-attention-100);
  --border-default: #E0E0E0;
  --border-brand-1: var(--color-brand-1-500);
  --border-brand-1-active: var(--color-brand-1-700);
  --border-brand-1-hover: var(--color-brand-1-600);
  --border-brand-1-inverted: var(--color-white-20);
  --border-brand-1-inverted-active: var(--color-white-60);
  --border-brand-1-inverted-hover: var(--color-white-40);
  --border-brand-2: var(--color-brand-2-500);
  --border-brand-2-active: var(--color-brand-2-700);
  --border-brand-2-hover: var(--color-brand-2-600);
  --border-disabled: var(--color-grey-300);
  --border-disabled-inverted: var(--color-white-30);
  --border-primary: var(--color-black-20);
  --border-primary-active: var(--color-black-60);
  --border-primary-hover: var(--color-black-40);
  --border-primary-inverted: var(--color-white-50);
  --border-primary-inverted-active: var(--color-white-90);
  --border-primary-inverted-hover: var(--color-white-70);
  --border-error: var(--color-support-error-500);
  --border-error-inverted: var(--color-support-error-300);
  --border-focus: undefined;
  --border-focus-inverted: undefined;
  --border-info: var(--color-support-information-500);
  --border-info-inverted: var(--color-support-information-300);
  --border-neutral: var(--color-grey-200);
  --border-success: var(--color-support-success-500);
  --border-success-inverted: var(--color-support-success-300);
  --border-warning: var(--color-support-attention-500);
  --border-warning-inverted: var(--color-support-attention-300);
  --text-brand: var(--color-brand-1-500);
  --text-brand-active: var(--color-brand-1-700);
  --text-brand-active-inverted: var(--color-white-100);
  --text-brand-hover: var(--color-brand-1-600);
  --text-brand-hover-inverted: var(--color-white-90);
  --text-brand-inverted: var(--color-white-100);
  --text-disabled: var(--color-grey-400);
  --text-disabled-inverted: var(--color-white-40);
  --text-link: var(--color-brand-1-500);
  --text-link-active: var(--color-brand-1-500);
  --text-link-active-inverted: var(--color-brand-1-100);
  --text-link-hover: var(--color-brand-1-600);
  --text-link-hover-inverted: var(--color-brand-1-200);
  --text-link-inverted: var(--color-brand-1-100);
  --text-placeholder: var(--color-grey-400);
  --text-placeholder-inverted: var(--color-white-70);
  --text-heading: var(--color-grey-900);
  --text-heading-inverted: var(--color-white-100);
  --text-primary: var(--color-grey-900);
  --text-primary-active: var(--color-brand-1-900);
  --text-primary-hover: var(--color-brand-1-700);
  --text-primary-inverted: var(--color-white-100);
  --text-primary-inverted-active: var(--color-white-40);
  --text-primary-inverted-hover: var(--color-white-70);
  --text-secondary: var(--color-black-50);
  --text-secondary-active: var(--color-black-90);
  --text-secondary-hover: var(--color-black-70);
  --text-secondary-inverted: var(--color-white-70);
  --text-secondary-inverted-active: var(--color-white-10);
  --text-secondary-inverted-hover: var(--color-white-40);
  --text-error: var(--color-support-error-500);
  --text-error-inverted: var(--color-support-error-300);
  --text-info: var(--color-support-information-500);
  --text-info-inverted: var(--color-support-information-300);
  --text-success: var(--color-support-success-500);
  --text-success-inverted: var(--color-support-success-300);
  --text-warning: var(--color-support-attention-500);
  --text-warning-inverted: var(--color-support-attention-300);
  --section-brand-dark: var(--color-brand-1-500);
  --section-brand-light: var(--color-brand-1-100);
  --section-neutral-dark: var(--color-grey-900);
  --section-neutral-light: var(--color-white-100);
  --shadow-300: 0px 2px 4px 0px rgba(0, 0, 0, 0.08), 0px 0px 6px 0px rgba(0, 0, 0, 0.02);
  --shadow-500: 0px 0px 1px 0px rgba(0, 0, 0, 0.04), 0px 2px 6px 0px rgba(0, 0, 0, 0.04), 0px 10px 20px 0px rgba(0, 0, 0, 0.04);
  --shadow-button: 0 0 0 4px rgba(0,0,0,0);
  --shadow-default: 0px 15px 30px 0px rgba(0, 0, 0, 0.05);
  --shadow-map-card: 0px 15px 30px 0px rgba(0, 0, 0, 0.05);
  --transition-all: all 200ms ease-out;
  --transition-color: color 200ms ease-out;
  --transition-background: background-color 200ms ease-out;
  --transition-transform: transform 200ms ease-out;
  --transition-opacity: opacity 200ms ease-out;
  --transition-border: border-color 200ms ease-out;
  --radius-sm: var(--spacing-1);
  --radius-md: var(--spacing-2);
  --radius-lg: var(--spacing-3);
  --radius-xl: var(--spacing-4);
  --radius-2xl: var(--spacing-5);
  --radius-3xl: var(--spacing-6);
  --radius-full: var(--spacing-99);
  /** Fonts **/
  --font-fallback: system-ui, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
  --font-default: Mulish;
  --font-heading: Mulish;
  /** Media **/
  --media-sm: 390px;
  --media-md: 768px;
  --media-lg: 1024px;
  --media-xl: 1280px;
  --media-xxl: 1440px;
  /** Spacings **/
  --spacing-0: calc(var(--unit) * 0);
  --spacing-1: calc(var(--unit) * 1);
  --spacing-2: calc(var(--unit) * 2);
  --spacing-3: calc(var(--unit) * 3);
  --spacing-4: calc(var(--unit) * 4);
  --spacing-5: calc(var(--unit) * 5);
  --spacing-6: calc(var(--unit) * 6);
  --spacing-7: calc(var(--unit) * 7);
  --spacing-8: calc(var(--unit) * 8);
  --spacing-9: calc(var(--unit) * 9);
  --spacing-10: calc(var(--unit) * 10);
  --spacing-12: calc(var(--unit) * 12);
  --spacing-14: calc(var(--unit) * 14);
  --spacing-16: calc(var(--unit) * 16);
  --spacing-18: calc(var(--unit) * 18);
  --spacing-20: calc(var(--unit) * 20);
  --spacing-22: calc(var(--unit) * 22);
  --spacing-24: calc(var(--unit) * 24);
  --spacing-30: calc(var(--unit) * 30);
  --spacing-99: calc(var(--unit) * 99);
  --spacing-0-5: calc(var(--unit) * 0.5);
  /** Spacings **/
  --molecule-xs: var(--spacing-1);
  @media (min-width: 390px) {
    --molecule-xs: var(--spacing-1);   
  }
  @media (min-width: 768px) {
    --molecule-xs: var(--spacing-1);   
  }
  @media (min-width: 1024px) {
    --molecule-xs: var(--spacing-2);   
  }
  @media (min-width: 1280px) {
    --molecule-xs: var(--spacing-2);   
  }
  @media (min-width: 1440px) {
    --molecule-xs: var(--spacing-2);   
  }
  --molecule-sm: var(--spacing-1);
  @media (min-width: 390px) {
    --molecule-sm: var(--spacing-2);   
  }
  @media (min-width: 768px) {
    --molecule-sm: var(--spacing-4);   
  }
  @media (min-width: 1024px) {
    --molecule-sm: var(--spacing-4);   
  }
  @media (min-width: 1280px) {
    --molecule-sm: var(--spacing-4);   
  }
  @media (min-width: 1440px) {
    --molecule-sm: var(--spacing-4);   
  }
  --molecule-md: var(--spacing-1);
  @media (min-width: 390px) {
    --molecule-md: var(--spacing-4);   
  }
  @media (min-width: 768px) {
    --molecule-md: var(--spacing-4);   
  }
  @media (min-width: 1024px) {
    --molecule-md: var(--spacing-6);   
  }
  @media (min-width: 1280px) {
    --molecule-md: var(--spacing-6);   
  }
  --molecule-lg: var(--spacing-2);
  @media (min-width: 390px) {
    --molecule-lg: var(--spacing-4);   
  }
  @media (min-width: 768px) {
    --molecule-lg: var(--spacing-6);   
  }
  @media (min-width: 1024px) {
    --molecule-lg: var(--spacing-8);   
  }
  --molecule-xl: var(--spacing-2);
  @media (min-width: 390px) {
    --molecule-xl: var(--spacing-4);   
  }
  @media (min-width: 768px) {
    --molecule-xl: var(--spacing-6);   
  }
  @media (min-width: 1024px) {
    --molecule-xl: var(--spacing-8);   
  }
  --atom-2xs: var(--spacing-0-5);
  @media (min-width: 390px) {
    --atom-2xs: var(--spacing-0-5);   
  }
  @media (min-width: 768px) {
    --atom-2xs: var(--spacing-0-5);   
  }
  @media (min-width: 1024px) {
    --atom-2xs: var(--spacing-0-5);   
  }
  @media (min-width: 1280px) {
    --atom-2xs: var(--spacing-0-5);   
  }
  @media (min-width: 1440px) {
    --atom-2xs: var(--spacing-0-5);   
  }
  --atom-xs: var(--spacing-1);
  @media (min-width: 390px) {
    --atom-xs: var(--spacing-1);   
  }
  @media (min-width: 768px) {
    --atom-xs: var(--spacing-1);   
  }
  @media (min-width: 1024px) {
    --atom-xs: var(--spacing-1);   
  }
  @media (min-width: 1280px) {
    --atom-xs: var(--spacing-1);   
  }
  @media (min-width: 1440px) {
    --atom-xs: var(--spacing-1);   
  }
  --atom-sm: var(--spacing-2);
  @media (min-width: 390px) {
    --atom-sm: var(--spacing-2);   
  }
  @media (min-width: 768px) {
    --atom-sm: var(--spacing-2);   
  }
  @media (min-width: 1024px) {
    --atom-sm: var(--spacing-2);   
  }
  @media (min-width: 1280px) {
    --atom-sm: var(--spacing-2);   
  }
  @media (min-width: 1440px) {
    --atom-sm: var(--spacing-2);   
  }
  --atom-md: var(--spacing-2);
  @media (min-width: 390px) {
    --atom-md: var(--spacing-2);   
  }
  @media (min-width: 768px) {
    --atom-md: var(--spacing-2);   
  }
  @media (min-width: 1024px) {
    --atom-md: var(--spacing-3);   
  }
  @media (min-width: 1280px) {
    --atom-md: var(--spacing-3);   
  }
  @media (min-width: 1440px) {
    --atom-md: var(--spacing-3);   
  }
  --atom-lg: var(--spacing-3);
  @media (min-width: 390px) {
    --atom-lg: var(--spacing-3);   
  }
  @media (min-width: 768px) {
    --atom-lg: var(--spacing-3);   
  }
  @media (min-width: 1024px) {
    --atom-lg: var(--spacing-4);   
  }
  @media (min-width: 1280px) {
    --atom-lg: var(--spacing-4);   
  }
  @media (min-width: 1440px) {
    --atom-lg: var(--spacing-4);   
  }
  --atom-xl: var(--spacing-5);
  @media (min-width: 390px) {
    --atom-xl: var(--spacing-5);   
  }
  @media (min-width: 768px) {
    --atom-xl: var(--spacing-5);   
  }
  @media (min-width: 1024px) {
    --atom-xl: var(--spacing-6);   
  }
  @media (min-width: 1280px) {
    --atom-xl: var(--spacing-6);   
  }
  @media (min-width: 1440px) {
    --atom-xl: var(--spacing-6);   
  }
  --atom-xxl: var(--spacing-8);
  @media (min-width: 390px) {
    --atom-xxl: var(--spacing-8);   
  }
  @media (min-width: 768px) {
    --atom-xxl: var(--spacing-8);   
  }
  @media (min-width: 1024px) {
    --atom-xxl: var(--spacing-12);   
  }
  @media (min-width: 1280px) {
    --atom-xxl: var(--spacing-12);   
  }
  @media (min-width: 1440px) {
    --atom-xxl: var(--spacing-12);   
  }
  --atom-null: var(--spacing-0);
  @media (min-width: 390px) {
    --atom-null: var(--spacing-0);   
  }
  @media (min-width: 768px) {
    --atom-null: var(--spacing-0);   
  }
  @media (min-width: 1024px) {
    --atom-null: var(--spacing-0);   
  }
  @media (min-width: 1280px) {
    --atom-null: var(--spacing-0);   
  }
  @media (min-width: 1440px) {
    --atom-null: var(--spacing-0);   
  }
  --block-sm: var(--spacing-8);
  @media (min-width: 390px) {
    --block-sm: var(--spacing-8);   
  }
  @media (min-width: 768px) {
    --block-sm: var(--spacing-8);   
  }
  @media (min-width: 1024px) {
    --block-sm: var(--spacing-10);   
  }
  @media (min-width: 1280px) {
    --block-sm: var(--spacing-10);   
  }
  @media (min-width: 1440px) {
    --block-sm: var(--spacing-10);   
  }
  --block-md: var(--spacing-10);
  @media (min-width: 390px) {
    --block-md: var(--spacing-10);   
  }
  @media (min-width: 768px) {
    --block-md: var(--spacing-10);   
  }
  @media (min-width: 1024px) {
    --block-md: var(--spacing-12);   
  }
  @media (min-width: 1280px) {
    --block-md: var(--spacing-12);   
  }
  @media (min-width: 1440px) {
    --block-md: var(--spacing-12);   
  }
  --block-lg: var(--spacing-12);
  @media (min-width: 390px) {
    --block-lg: var(--spacing-12);   
  }
  @media (min-width: 768px) {
    --block-lg: var(--spacing-12);   
  }
  @media (min-width: 1024px) {
    --block-lg: var(--spacing-14);   
  }
  @media (min-width: 1280px) {
    --block-lg: var(--spacing-14);   
  }
  @media (min-width: 1440px) {
    --block-lg: var(--spacing-14);   
  }
  --block-xl: var(--spacing-18);
  @media (min-width: 390px) {
    --block-xl: var(--spacing-18);   
  }
  @media (min-width: 768px) {
    --block-xl: var(--spacing-18);   
  }
  @media (min-width: 1024px) {
    --block-xl: var(--spacing-20);   
  }
  @media (min-width: 1280px) {
    --block-xl: var(--spacing-20);   
  }
  @media (min-width: 1440px) {
    --block-xl: var(--spacing-20);   
  }
  --block-top-bottom: var(--spacing-8);
  @media (min-width: 390px) {
    --block-top-bottom: var(--spacing-8);   
  }
  @media (min-width: 768px) {
    --block-top-bottom: var(--spacing-10);   
  }
  @media (min-width: 1024px) {
    --block-top-bottom: var(--spacing-20);   
  }
  @media (min-width: 1280px) {
    --block-top-bottom: var(--spacing-20);   
  }
  @media (min-width: 1440px) {
    --block-top-bottom: var(--spacing-20);   
  }
  --block-horizontal: var(--spacing-10);
  @media (min-width: 390px) {
    --block-horizontal: var(--spacing-10);   
  }
  @media (min-width: 768px) {
    --block-horizontal: var(--spacing-10);   
  }
  @media (min-width: 1024px) {
    --block-horizontal: var(--spacing-12);   
  }
  @media (min-width: 1280px) {
    --block-horizontal: var(--spacing-12);   
  }
  @media (min-width: 1440px) {
    --block-horizontal: var(--spacing-12);   
  }
  --block-xxl: var(--spacing-16);
  @media (min-width: 390px) {
    --block-xxl: var(--spacing-16);   
  }
  @media (min-width: 768px) {
    --block-xxl: var(--spacing-16);   
  }
  @media (min-width: 1024px) {
    --block-xxl: var(--spacing-30);   
  }
  @media (min-width: 1280px) {
    --block-xxl: var(--spacing-30);   
  }
  @media (min-width: 1440px) {
    --block-xxl: var(--spacing-30);   
  }
  --block-null: var(--spacing-0);
  @media (min-width: 390px) {
    --block-null: var(--spacing-0);   
  }
  @media (min-width: 768px) {
    --block-null: var(--spacing-0);   
  }
  @media (min-width: 1024px) {
    --block-null: var(--spacing-0);   
  }
  @media (min-width: 1280px) {
    --block-null: var(--spacing-0);   
  }
  @media (min-width: 1440px) {
    --block-null: var(--spacing-0);   
  }
  --grid-gutter: var(--spacing-4);
  @media (min-width: 390px) {
    --grid-gutter: var(--spacing-4);   
  }
  @media (min-width: 768px) {
    --grid-gutter: var(--spacing-6);   
  }
  @media (min-width: 1024px) {
    --grid-gutter: var(--spacing-6);   
  }
  @media (min-width: 1280px) {
    --grid-gutter: var(--spacing-6);   
  }
  @media (min-width: 1440px) {
    --grid-gutter: var(--spacing-6);   
  }
  --grid-left-right: var(--spacing-4);
  @media (min-width: 390px) {
    --grid-left-right: var(--spacing-4);   
  }
  @media (min-width: 768px) {
    --grid-left-right: var(--spacing-10);   
  }
  @media (min-width: 1024px) {
    --grid-left-right: var(--spacing-20);   
  }
  @media (min-width: 1280px) {
    --grid-left-right: var(--spacing-20);   
  }
  @media (min-width: 1440px) {
    --grid-left-right: var(--spacing-20);   
  }
}
/** Typography **/
.heading-100 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 900;
}
.heading-200 {
  font-size: 18px;
  line-height: 24px;
  font-weight: 900;
}
.heading-300 {
  font-size: 22px;
  line-height: 28px;
  font-weight: 900;
}
.heading-400 {
  font-size: 28px;
  line-height: 36px;
  font-weight: 900;
}
.heading-500 {
  font-size: 36px;
  line-height: 48px;
  font-weight: 900;
}
.heading-600 {
  font-size: 48px;
  line-height: 56px;
  font-weight: 900;
}
.heading-700 {
  font-size: 56px;
  line-height: 64px;
  font-weight: 900;
}
.heading-800 {
  font-size: 64px;
  line-height: 72px;
  font-weight: 900;
}
.heading-900 {
  font-size: 72px;
  line-height: 84px;
  font-weight: 900;
}
.heading-950 {
  font-size: 80px;
  font-weight: 900;
  line-height: 96px;
}
.body-100 {
  font-size: 10px;
  line-height: 16px;
}
.body-200 {
  font-size: 12px;
  line-height: 16px;
}
.body-300 {
  font-size: 14px;
  line-height: 20px;
}
.body-400 {
  font-size: 16px;
  line-height: 24px;
}
.body-500 {
  font-size: 18px;
  line-height: 24px;
}
.body-600 {
  font-size: 20px;
  line-height: 24px;
}
@media (min-width: 390px) {
  .sm\:heading-100 {
    font-size: 16px;
    line-height: 24px;
    font-weight: 900;  
  }
}
@media (min-width: 390px) {
  .sm\:heading-200 {
    font-size: 18px;
    line-height: 24px;
    font-weight: 900;  
  }
}
@media (min-width: 390px) {
  .sm\:heading-300 {
    font-size: 22px;
    line-height: 28px;
    font-weight: 900;  
  }
}
@media (min-width: 390px) {
  .sm\:heading-400 {
    font-size: 28px;
    line-height: 36px;
    font-weight: 900;  
  }
}
@media (min-width: 390px) {
  .sm\:heading-500 {
    font-size: 36px;
    line-height: 48px;
    font-weight: 900;  
  }
}
@media (min-width: 390px) {
  .sm\:heading-600 {
    font-size: 48px;
    line-height: 56px;
    font-weight: 900;  
  }
}
@media (min-width: 390px) {
  .sm\:heading-700 {
    font-size: 56px;
    line-height: 64px;
    font-weight: 900;  
  }
}
@media (min-width: 390px) {
  .sm\:heading-800 {
    font-size: 64px;
    line-height: 72px;
    font-weight: 900;  
  }
}
@media (min-width: 390px) {
  .sm\:heading-900 {
    font-size: 72px;
    line-height: 84px;
    font-weight: 900;  
  }
}
@media (min-width: 390px) {
  .sm\:heading-950 {
    font-size: 80px;
    font-weight: 900;
    line-height: 96px;  
  }
}
@media (min-width: 390px) {
  .sm\:body-100 {
    font-size: 10px;
    line-height: 16px;  
  }
}
@media (min-width: 390px) {
  .sm\:body-200 {
    font-size: 12px;
    line-height: 16px;  
  }
}
@media (min-width: 390px) {
  .sm\:body-300 {
    font-size: 14px;
    line-height: 20px;  
  }
}
@media (min-width: 390px) {
  .sm\:body-400 {
    font-size: 16px;
    line-height: 24px;  
  }
}
@media (min-width: 390px) {
  .sm\:body-500 {
    font-size: 18px;
    line-height: 24px;  
  }
}
@media (min-width: 390px) {
  .sm\:body-600 {
    font-size: 20px;
    line-height: 24px;  
  }
}
@media (min-width: 768px) {
  .md\:heading-100 {
    font-size: 16px;
    line-height: 24px;
    font-weight: 900;  
  }
}
@media (min-width: 768px) {
  .md\:heading-200 {
    font-size: 18px;
    line-height: 24px;
    font-weight: 900;  
  }
}
@media (min-width: 768px) {
  .md\:heading-300 {
    font-size: 22px;
    line-height: 28px;
    font-weight: 900;  
  }
}
@media (min-width: 768px) {
  .md\:heading-400 {
    font-size: 28px;
    line-height: 36px;
    font-weight: 900;  
  }
}
@media (min-width: 768px) {
  .md\:heading-500 {
    font-size: 36px;
    line-height: 48px;
    font-weight: 900;  
  }
}
@media (min-width: 768px) {
  .md\:heading-600 {
    font-size: 48px;
    line-height: 56px;
    font-weight: 900;  
  }
}
@media (min-width: 768px) {
  .md\:heading-700 {
    font-size: 56px;
    line-height: 64px;
    font-weight: 900;  
  }
}
@media (min-width: 768px) {
  .md\:heading-800 {
    font-size: 64px;
    line-height: 72px;
    font-weight: 900;  
  }
}
@media (min-width: 768px) {
  .md\:heading-900 {
    font-size: 72px;
    line-height: 84px;
    font-weight: 900;  
  }
}
@media (min-width: 768px) {
  .md\:heading-950 {
    font-size: 80px;
    font-weight: 900;
    line-height: 96px;  
  }
}
@media (min-width: 768px) {
  .md\:body-100 {
    font-size: 10px;
    line-height: 16px;  
  }
}
@media (min-width: 768px) {
  .md\:body-200 {
    font-size: 12px;
    line-height: 16px;  
  }
}
@media (min-width: 768px) {
  .md\:body-300 {
    font-size: 14px;
    line-height: 20px;  
  }
}
@media (min-width: 768px) {
  .md\:body-400 {
    font-size: 16px;
    line-height: 24px;  
  }
}
@media (min-width: 768px) {
  .md\:body-500 {
    font-size: 18px;
    line-height: 24px;  
  }
}
@media (min-width: 768px) {
  .md\:body-600 {
    font-size: 20px;
    line-height: 24px;  
  }
}
@media (min-width: 1024px) {
  .lg\:heading-100 {
    font-size: 16px;
    line-height: 24px;
    font-weight: 900;  
  }
}
@media (min-width: 1024px) {
  .lg\:heading-200 {
    font-size: 18px;
    line-height: 24px;
    font-weight: 900;  
  }
}
@media (min-width: 1024px) {
  .lg\:heading-300 {
    font-size: 22px;
    line-height: 28px;
    font-weight: 900;  
  }
}
@media (min-width: 1024px) {
  .lg\:heading-400 {
    font-size: 28px;
    line-height: 36px;
    font-weight: 900;  
  }
}
@media (min-width: 1024px) {
  .lg\:heading-500 {
    font-size: 36px;
    line-height: 48px;
    font-weight: 900;  
  }
}
@media (min-width: 1024px) {
  .lg\:heading-600 {
    font-size: 48px;
    line-height: 56px;
    font-weight: 900;  
  }
}
@media (min-width: 1024px) {
  .lg\:heading-700 {
    font-size: 56px;
    line-height: 64px;
    font-weight: 900;  
  }
}
@media (min-width: 1024px) {
  .lg\:heading-800 {
    font-size: 64px;
    line-height: 72px;
    font-weight: 900;  
  }
}
@media (min-width: 1024px) {
  .lg\:heading-900 {
    font-size: 72px;
    line-height: 84px;
    font-weight: 900;  
  }
}
@media (min-width: 1024px) {
  .lg\:heading-950 {
    font-size: 80px;
    font-weight: 900;
    line-height: 96px;  
  }
}
@media (min-width: 1024px) {
  .lg\:body-100 {
    font-size: 10px;
    line-height: 16px;  
  }
}
@media (min-width: 1024px) {
  .lg\:body-200 {
    font-size: 12px;
    line-height: 16px;  
  }
}
@media (min-width: 1024px) {
  .lg\:body-300 {
    font-size: 14px;
    line-height: 20px;  
  }
}
@media (min-width: 1024px) {
  .lg\:body-400 {
    font-size: 16px;
    line-height: 24px;  
  }
}
@media (min-width: 1024px) {
  .lg\:body-500 {
    font-size: 18px;
    line-height: 24px;  
  }
}
@media (min-width: 1024px) {
  .lg\:body-600 {
    font-size: 20px;
    line-height: 24px;  
  }
}
@media (min-width: 1280px) {
  .xl\:heading-100 {
    font-size: 16px;
    line-height: 24px;
    font-weight: 900;  
  }
}
@media (min-width: 1280px) {
  .xl\:heading-200 {
    font-size: 18px;
    line-height: 24px;
    font-weight: 900;  
  }
}
@media (min-width: 1280px) {
  .xl\:heading-300 {
    font-size: 22px;
    line-height: 28px;
    font-weight: 900;  
  }
}
@media (min-width: 1280px) {
  .xl\:heading-400 {
    font-size: 28px;
    line-height: 36px;
    font-weight: 900;  
  }
}
@media (min-width: 1280px) {
  .xl\:heading-500 {
    font-size: 36px;
    line-height: 48px;
    font-weight: 900;  
  }
}
@media (min-width: 1280px) {
  .xl\:heading-600 {
    font-size: 48px;
    line-height: 56px;
    font-weight: 900;  
  }
}
@media (min-width: 1280px) {
  .xl\:heading-700 {
    font-size: 56px;
    line-height: 64px;
    font-weight: 900;  
  }
}
@media (min-width: 1280px) {
  .xl\:heading-800 {
    font-size: 64px;
    line-height: 72px;
    font-weight: 900;  
  }
}
@media (min-width: 1280px) {
  .xl\:heading-900 {
    font-size: 72px;
    line-height: 84px;
    font-weight: 900;  
  }
}
@media (min-width: 1280px) {
  .xl\:heading-950 {
    font-size: 80px;
    font-weight: 900;
    line-height: 96px;  
  }
}
@media (min-width: 1280px) {
  .xl\:body-100 {
    font-size: 10px;
    line-height: 16px;  
  }
}
@media (min-width: 1280px) {
  .xl\:body-200 {
    font-size: 12px;
    line-height: 16px;  
  }
}
@media (min-width: 1280px) {
  .xl\:body-300 {
    font-size: 14px;
    line-height: 20px;  
  }
}
@media (min-width: 1280px) {
  .xl\:body-400 {
    font-size: 16px;
    line-height: 24px;  
  }
}
@media (min-width: 1280px) {
  .xl\:body-500 {
    font-size: 18px;
    line-height: 24px;  
  }
}
@media (min-width: 1280px) {
  .xl\:body-600 {
    font-size: 20px;
    line-height: 24px;  
  }
}
@media (min-width: 1440px) {
  .xxl\:heading-100 {
    font-size: 16px;
    line-height: 24px;
    font-weight: 900;  
  }
}
@media (min-width: 1440px) {
  .xxl\:heading-200 {
    font-size: 18px;
    line-height: 24px;
    font-weight: 900;  
  }
}
@media (min-width: 1440px) {
  .xxl\:heading-300 {
    font-size: 22px;
    line-height: 28px;
    font-weight: 900;  
  }
}
@media (min-width: 1440px) {
  .xxl\:heading-400 {
    font-size: 28px;
    line-height: 36px;
    font-weight: 900;  
  }
}
@media (min-width: 1440px) {
  .xxl\:heading-500 {
    font-size: 36px;
    line-height: 48px;
    font-weight: 900;  
  }
}
@media (min-width: 1440px) {
  .xxl\:heading-600 {
    font-size: 48px;
    line-height: 56px;
    font-weight: 900;  
  }
}
@media (min-width: 1440px) {
  .xxl\:heading-700 {
    font-size: 56px;
    line-height: 64px;
    font-weight: 900;  
  }
}
@media (min-width: 1440px) {
  .xxl\:heading-800 {
    font-size: 64px;
    line-height: 72px;
    font-weight: 900;  
  }
}
@media (min-width: 1440px) {
  .xxl\:heading-900 {
    font-size: 72px;
    line-height: 84px;
    font-weight: 900;  
  }
}
@media (min-width: 1440px) {
  .xxl\:heading-950 {
    font-size: 80px;
    font-weight: 900;
    line-height: 96px;  
  }
}
@media (min-width: 1440px) {
  .xxl\:body-100 {
    font-size: 10px;
    line-height: 16px;  
  }
}
@media (min-width: 1440px) {
  .xxl\:body-200 {
    font-size: 12px;
    line-height: 16px;  
  }
}
@media (min-width: 1440px) {
  .xxl\:body-300 {
    font-size: 14px;
    line-height: 20px;  
  }
}
@media (min-width: 1440px) {
  .xxl\:body-400 {
    font-size: 16px;
    line-height: 24px;  
  }
}
@media (min-width: 1440px) {
  .xxl\:body-500 {
    font-size: 18px;
    line-height: 24px;  
  }
}
@media (min-width: 1440px) {
  .xxl\:body-600 {
    font-size: 20px;
    line-height: 24px;  
  }
}