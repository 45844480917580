@import "../../../styles/mixins";

.image-marker {
  display: flex;
  justify-content: center;
  align-items: center;
  width: gap(8);
  height: gap(8);
  border-radius: 50%;
  position: relative;
  background-color: rgba(0, 0, 0, 0.30);
  cursor: pointer;
  padding: gap(1.5);
  transition: var(--transition-all);

  @include media(lg) {
    width: gap(12);
    height: gap(12);
    padding: gap(3);
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.40);
  }

  &.active {
    background-color: rgba(0, 0, 0, 0.40);
    padding: gap(1);

    @include media(lg) {
      padding: gap(2);
    }
  }

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: var(--color-white-100);
  }
}
