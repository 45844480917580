@import "../../../styles/mixins";
@import "../../../styles/theme";

.b-news-detail {
  $self: &;
  overflow: hidden;

  position: relative;
  @extend .body-400;

  &__container {
    @include grid(1, 1, 12, 12, 12);
    grid-gap: gap(6);
    z-index: 1;
    position: relative;
    padding-top: gap(8);
    padding-bottom: gap(8);

    @include media(lg) {
      padding-top: gap(20);
      padding-bottom: gap(20);
    }

    a {
      color: var(--text-brand);
    }

    strong {
      font-weight: 600;
    }

    p, h1, h2, h3, h4, h5, h6, ul, ol, {
      @include media(lg) {
        grid-column-start: 2;
        grid-column-end: 9;
      }
    }

    h1, h2, h3, h4, h5, h6 {
      padding-top: gap(7);
      text-transform: uppercase;

      @include media(lg) {
        padding-top: gap(14);
      }
    }

    h4, h5, h6 {
      font-size: 16px;
      line-height: 24px;
      font-weight: 900;
    }
    h3 {
      font-size: 18px;
      line-height: 24px;
      font-weight: 900;
    }
    h2 {
      font-size: 22px;
      line-height: 28px;
      font-weight: 900;
    }
    h1 {
      font-size: 28px;
      line-height: 36px;
      font-weight: 900;
    }

    ul {
      display: flex;
      flex-direction: column;
      gap: gap(5);

      li {
        display: flex;
        gap: gap(2);

        &::before {
          content: url('data:image/svg+xml,<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23clip0_3027_70824)"><path d="M19.8204 32C18.4648 31.9136 17.1746 31.5851 15.9171 31.101C13.8757 30.3231 12.0792 29.1302 10.544 27.5224C9.2865 26.2258 8.27395 24.7045 7.5227 23.0103C7.00009 21.8347 6.57547 20.6245 6.34683 19.3452C6.15085 18.2215 5.9712 17.0805 6.00386 15.9222C6.06919 13.8995 6.37949 11.9287 7.09808 10.0443C7.89832 7.95246 9.05786 6.11994 10.593 4.52945C12.5201 2.52404 14.8066 1.21016 17.4033 0.501354C17.9096 0.363052 18.4322 0.311185 18.9548 0.207458C19.2651 0.138305 19.5917 0.0691522 19.902 -6.29379e-07C19.9347 0.0172876 19.951 0.0172895 19.9837 0.0345777C19.9837 1.90168 19.9837 3.76878 19.9837 5.68774C19.8367 5.7396 19.6897 5.80875 19.5427 5.82604C18.8568 5.87791 18.2199 6.08536 17.5666 6.32739C17.24 6.44841 16.9133 6.58671 16.603 6.7423C14.6106 7.69314 13.1571 9.24906 12.1282 11.2717C11.7036 12.1016 11.4096 13.0005 11.2136 13.9341C10.985 15.0405 10.9197 16.1815 11.0993 17.3052C11.7199 21.4889 13.9737 24.2723 17.6646 25.7591C18.3015 26.0184 19.0038 26.1221 19.6734 26.295C19.7714 26.3123 19.853 26.3296 20 26.3641C20 28.2312 20 30.081 20 31.9308C19.9347 31.9481 19.8693 31.9654 19.8204 32Z" fill="%232F80ED"/></g><defs><clipPath id="clip0_3027_70824"><rect width="32" height="32" fill="white"/></clipPath></defs></svg>');
        }
      }
    }

    ol {
      display: flex;
      flex-direction: column;
      gap: gap(5);
      list-style: none;
      counter-reset: my-awesome-counter;

      li {
        counter-increment: my-awesome-counter;
        display: flex;
        gap: gap(2);

        &::before {
          content: counter(my-awesome-counter) ". ";
        }
      }
    }

    figure {
      aspect-ratio: 602 / 450;
      width: 100%;
      overflow: hidden;
      margin: 0;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: gap(4);
      }

      @include media(lg) {
        grid-column: span 6;
        margin: gap(20) 0;

        &:nth-child(odd) {
          padding-left: gap(6.5);
        }

        &:nth-child(even) {
          padding-right: gap(6.5);
        }
      }
    }
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: gap(4) 0;
    color: var(--text-secondary);
    text-transform: uppercase;
  }

  &__symbol {
    display: none;

    @include media(lg) {
      position: absolute;
      top: gap(56);
      display: block;
      grid-column-start: 11;
      grid-column-end: 13;
    }
  }

  &__wave {
    color: var(--section-neutral-dark) !important;
  }
}
