@import "../../../styles/mixins";

.b-simple-text {
  $self: &;

  background-color: var(--color-white-100);
  color: var(--color-grey-900);
  padding: 0 gap(10);

  @include media(lg) {
    padding: gap(20) gap(10);
  }

  & + &:not(:first-child) {
    padding-top: 0;
  }

  &--dark {
    background-color: var(--section-neutral-dark);
    color: var(--color-white-100);
  }

  &--blue {
    background-color: var(--section-brand-light);
  }

  &__container {
    @include grid(1, 1, 12, 12, 12);
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: gap(6);

    @include media(lg) {
      gap: gap(8);

      grid-column-start: 1;
      grid-column-end: 7;
    }

    &--left-2\/3 {
      @include media(lg) {
        grid-column-start: 1;
        grid-column-end: 9;
      }
    }

    &--right-1\/2 {
      @include media(lg) {
        grid-column-start: 7;
        grid-column-end: 13;
      }
    }

    &--right-2\/3 {
      @include media(lg) {
        grid-column-start: 5;
        grid-column-end: 13;
      }
    }

    &--center-1\/2,
    &--center-2\/3 {
      display: flex;
      align-items: center;
      text-align: center;
    }

    &--center-1\/2 {
      @include media(lg) {
        grid-column-start: 4;
        grid-column-end: 10;
      }
    }

    &--center-2\/3 {
      @include media(lg) {
        grid-column-start: 3;
        grid-column-end: 11;
      }
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: gap(2);

    @include media(lg) {
      gap: gap(4);
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    gap: gap(4);
    white-space: pre-line;
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: gap(4);
  }
}
