@import "../../../styles/mixins";

.slider-navigation {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;

  &--prev {
    left: gap(4);
  }

  &--next {
    right: gap(4);
  }
}
