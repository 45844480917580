@import "../../../styles/mixins";

.select {
  $self: &;
  position: relative;
  border-radius: var(--radius-sm);
  border: 1px solid var(--border-primary);
  background-color: var(--bg-neutral-light);

  &--disabled {
    background-color: var(--bg-disabled-1);
    border-color: var(--border-disabled) !important;

    #{ $self }__data-entry {
      color: var(--text-disabled);
    }

    .icon {
      color: var(--icon-disabled);
    }
  }

  &--error {
    border-color: var(--border-error);
  }

  &:hover {
    border-color: var(--border-brand-1-hover);
  }

  &:focus-within {
    border-color: var(--border-brand-1-active);
  }

  &__data-entry {
    width: 100%;
    border: none;
    outline: none;
    color: var(--text-primary);
    padding: gap(3) gap(8) gap(2.5) gap(4);
    appearance: none;
    -webkit-appearance: none;
    background-color: transparent;
  }

  .icon {
    position: absolute;
    top: 50%;
    right: gap(4);
    transform: translateY(-50%);
    color: var(--icon-secondary);
  }

  &__error {
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    width: 100%;
    color: var(--text-error);
  }
}