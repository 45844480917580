.body-underline {
  text-decoration: underline;
  text-underline-offset: 20%;
}

.body-strikethrough {
  text-decoration: line-through;
}

.body-bold {
  font-weight: 700 !important;
}
