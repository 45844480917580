@import "../../../styles/mixins";

.b-card-grid {
  background-color: var(--color-white-100);
  padding: gap(10) 0 gap(16);
  position: relative;
  display: flex;
  flex-direction: column;

  @include media(lg) {
    gap: gap(10);
    padding-bottom: gap(28);
  }

  &__filters {
    padding: gap(4) 0;
    display: flex;
    flex-direction: column;
    gap: gap(4);

    @include media(lg) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      padding: gap(4) gap(8);
      border-radius: var(--radius-full);
      background-color: var(--bg-neutral-light);
      box-shadow: var(--shadow-default);
    }

    &-group {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: gap(4);

      &-label {
        color: var(--text-primary);

        @include media(lg, max) {
          display: none;
        }
      }

      &-list {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        gap: gap(0.5);
      }
    }
  }

  &__container {
    @include grid(1, 1, 3, 3, 3);
    grid-gap: gap(7);
    position: relative;
    z-index: 1;

    @include media(lg) {
      grid-gap: gap(20) gap(8);
    }

    &.hidden {
      display: none;
    }
  }

  &__wave {
    color: var(--section-neutral-dark) !important;

    @include media(lg, max) {
      display: none !important;
    }
  }
}
