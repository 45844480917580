@import "../../../styles/mixins";

.logo-slider {
  overflow: hidden;
  background-color: var(--bg-neutral-dark);

  &__inner {
    padding: gap(6) 0;
    display: flex;
    flex-direction: column;
    gap: gap(2.5);

    @include media(lg) {
      padding: gap(10) 0;
    }
  }

  &__brands {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: gap(4);
  }

  &__mobile-extra-list {
    @include media(lg) {
      display: none;
    }
  }

  &__image {
    display: block;
    width: 100%;
    max-width: gap(39.25);
    min-width: gap(39.25);
    aspect-ratio: 157 / 75;
    object-fit: contain;
  }
}

