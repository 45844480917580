@import "../../../../styles/mixins";

.c-market {
  background-color: var(--color-white-100);
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--atom-xl);
  box-shadow: var(--shadow-default);
  border-radius: gap(4);
  color: var(--color-grey-900);
  overflow: hidden;

  &__title {
    padding: var(--atom-xl) gap(6);
  }

  &__content {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;
  }

  &__button {
    padding: var(--atom-lg) var(--molecule-md);
  }

  &__thumb-inner {
    width: 203px;
    height: 94px;
    transform: translateX(50%);
    position: absolute;
    right: 0;
  }

  &__thumb {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
