@import "../../../styles/mixins";

.slider-navigation-button {
  width: gap(6.75);
  height: gap(6.75);
  min-width: gap(6.75);
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: var(--radius-full);
  background-color: var(--color-white-100);
  color: var(--icon-primary);
  cursor: pointer;
  transition: var(--transition-color), var(--transition-transform);

  &:hover {
    color: var(--icon-primary-hover);
  }

  &:active {
    cursor: var(--icon-primary-active);
    transform: scale(0.95);
  }

  &--type-big {
    width: gap(13.25);
    height: gap(13.25);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    i {
      font-size: 20px;
    }
  }
}
