@import "../../../../../styles/mixins";

.header-mobile {
  $self: &;
  display: flex;
  flex-direction: column;
  gap: var(--atom-md);

  &.active {
    #{ $self }__menu {
      opacity: 1;
      visibility: visible;
      pointer-events: initial;
    }
  }

  &__main {
    position: relative;
    padding-top: var(--atom-lg);
  }

  &__search {
    padding-bottom: var(--atom-lg);
  }

  &__head {
    &-inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: gap(4);
    }

    &-left {}
    &-right {}
  }

  &__logo {
    width: 100%;
    max-width: gap(30.5);

    &.is-hidden {
      display: none;
    }
  }

  &__menu {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: calc(100vh - var(--header-mobile-menu-offset-height));
    height: calc(var(--vh) * 100 - var(--header-mobile-menu-offset-height));
    z-index: 99999;
    overflow-y: auto;
    background: var(--color-white-100)  url("../../../../../assets/images/vector.svg") bottom 156px right no-repeat;


    &.active {
      opacity: 1;
      visibility: visible;
      pointer-events: initial;
    }

    &-vector {
      position: absolute;
      right: 0;
      z-index: 0;
    }

    .container {
      height: 100%;
    }

    &-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: gap(4);
      border-top: 1px solid var(--border-default);
      padding: gap(6) gap(2);
      height: 100%;
    }

    &-top {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: gap(4);
      position: relative;

      &.is-visibility-hidden {
        visibility: hidden;
        pointer-events: none;
      }
    }

    &-bottom {
      display: flex;
      flex-direction: column;
      gap: gap(2.5);

      .button {
        justify-content: center;
      }
    }
  }

  &__navigation {
    padding-bottom: gap(4);
    border-bottom: 1px solid var(--border-default);

    &-list {
      display: flex;
      flex-direction: column;
      gap: gap(6);
    }

    &-item {
      color: var(--text-primary);
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: gap(4);

      .icon {
        color: var(--icon-primary);
      }
    }
  }

  &__language {
    text-align: center;
  }

  button {
    &.header-mobile__button {
      color: var(--icon-primary);
      background-color: var(--bg-neutral-light);

      &:hover {
        color: var(--icon-primary-hover);
        background-color: var(--bg-neutral-light-hover);
      }


      &:active {
        color: var(--icon-primary-active);
        background-color: var(--bg-neutral-light-active);
      }

      &.is-hidden {
        display: none;
      }
    }
  }
}

.header-mobile-submenu {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  overflow-y: auto;

  position: absolute;
  inset: 0;

  &.active {
    opacity: 1;
    visibility: visible;
    pointer-events: initial;
  }

  &.is-visibility-hidden {
    visibility: hidden;
    pointer-events: none;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: gap(6);
  }

  &__item {
    color: var(--text-primary);
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: gap(4);

    .icon {
      color: var(--icon-primary);
    }
  }
}
