@import "../../../styles/mixins";

.pagination {
  $self: &;

  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 1;

  &__inner {
    padding: gap(8) 0;
  }

  &__container {
    display: flex;
    justify-content: center;
  }

  &__button {
    display: none;

    @include media(lg) {
      display: block;
    }
  }

  &__row,
  &__list {
    display: flex;
    align-items: center;
    gap: gap(2);

    @include media(lg, max) {
      gap: gap();
    }
  }

  &__item {
    width: gap(10);
    height: gap(10);
    transition: var(--transition-all);
    color: var(--text-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 1px solid var(--border-primary);
    border-radius: gap();

    &.active,
    &:hover {
      color: var(--color-white-100);
      background-color: var(--bg-brand-dark);
      border-color: transparent;
    }

    &_no-border {
      border: none;
      pointer-events: none;
    }
  }
}
