@import "../../../styles/mixins";

.dropdown {
  $self: &;
  position: relative;
  display: inline-block;

  &.active {
    #{ $self }__content {
      opacity: 1;
      visibility: visible;
      pointer-events: initial;
      z-index: 999999;
    }
  }

  &--placement-bottom-start {
    #{ $self }__content {
      left: 0;
    }
  }

  &--placement-bottom-end {
    #{ $self }__content {
      right: 0;
    }
  }

  &__content {
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
    width: 100%;

    @include media(lg, max) {
      position: fixed;
      top: 0;
      height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      background-color: var(--color-black-50);
    }

    @include media(lg) {
      position: absolute;
      top: calc(100% + 4px);
      min-width: gap(75);
    }
  }

  &__body {
    background-color: var(--color-white-100);
    border-radius: var(--radius-md) var(--radius-md) 0 0;

    @include media(lg) {
      border-radius: var(--radius-md);
      box-shadow: var(--shadow-500);
      border: 1px solid var(--border-primary);
    }

    &-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: var(--atom-lg);
      padding: var(--atom-sm) var(--atom-lg);

      @include media(lg) {
        display: none;
      }
    }

    &-label {
      color: var(--text-heading);
    }

    &-content {
      padding: var(--atom-sm) 0;
    }
  }
}