html {
  height: 100%;
  box-sizing: border-box;

  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-feature-settings: 'liga' 1;
  font-feature-settings: 'liga' 1;
  scroll-behavior: smooth;
}

body {
  height: auto;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-family: var(--font-mulish), var(--font-fallback);

  &.is-overflow-hidden {
    overflow: hidden;
  }
}

*,
::before,
::after {
  box-sizing: border-box;
}

.hidden {
  display: none !important;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
