@import "../../../styles/mixins";
@import "../../../node_modules/tippy.js/animations/scale.css";

.b-image-grid {
  background-color: var(--bg-neutral-light);
  padding: gap(8) 0;
  overflow: hidden;

  @include media(lg) {
    padding: gap(20) 0;
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: gap(8);

    @include media(lg) {
      gap: gap(10)
    }
  }

  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: gap(4);
    color: var(--text-primary);
  }

  &__link {
    @include media(lg, max) {
      display: none;
    }

    &--mobile {
      display: block;

      @include media(lg) {
        display: none;
      }
    }
  }

  &__content {
    display: none;

    @include media(lg) {
      border-radius: gap(4);
      overflow: hidden;
      display: flex;
      flex-direction: column;
      gap: gap();
    }
  }

  &__slider {
    overflow: visible;

    @include media(lg) {
      display: none;
    }
  }

  &__slide {
    max-width: gap(72);
    width: 100%;
    overflow: visible;
  }

  &__group {
    @include grid(1, 1, 12, 12, 12);
    grid-gap: gap();
  }

  &__media {
    position: relative;
    grid-column: span 4;

    &--big {
      grid-column: span 6;

      img {
        aspect-ratio: 640 / 402;
      }
    }

    img {
      width: 100%;
      aspect-ratio: 420 / 402;
      object-fit: cover;

      @include media(lg, max) {
        border-radius: gap(4);
      }
    }
  }

  &__marker {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3D(-50%, -50%, 0);
  }

  &__popup {
    display: none;
  }

  &__info {
    overflow: hidden;
    border-radius: gap(2);
    padding: gap(4) gap(6);
    background-color: var(--color-white-100);
    box-shadow: var(--shadow-500);
    display: flex;
    flex-direction: column;
    gap: gap(2);
  }

  &__info-title {
    color: var(--text-primary);
  }

  &__info-description {
    color: var(--text-secondary);
  }
}
