@import "../../../styles/mixins";

.navigation-link {
  cursor: pointer;
  position: relative;
  text-decoration: none;
  color: var(--text-primary);
  transition: var(--transition-color);
  padding-bottom: var(--spacing-7);
  display: flex;
  align-items: center;
  gap: gap(1);

  &:after {
    display: block;
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: gap(0.75);
    transition: var(--transition-background);
  }
  
  &:hover, &:active, &.active {
    color: var(--text-brand);

    .icon {
      transform: rotate(180deg);
    }

    &:after {
      background-color: var(--bg-brand-dark);
    }
  }

  .icon {
    color: var(--icon-primary);
    transition: var(--transition-transform);
  }
}