@import "../../../styles/mixins";

.icon {
  &--size {
    &-small {
      font-size: gap(4);
    }

    &-medium {
      font-size: gap(5);
    }

    &-large {
      font-size: gap(5.5);
    }

    &-xlarge {
      font-size: gap(10);
    }
  }
}