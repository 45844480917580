@import "../../../styles/mixins";

.map {

  &__inner {
    padding: gap(20) 0;
  }

  &__title {
    color: var(--text-heading);
  }

  &__content {
    padding-top: gap(8);
    display: flex;
    flex-direction: column-reverse;
    gap: gap(10);

    @include media(lg) {
      @include grid(1, 1, 12, 12, 12);
      grid-gap: gap(6);
    }

    &-main {
      display: flex;
      flex-direction: column;
      gap: gap(6);

      @include media(lg) {
        grid-column: 1 / 6;
      }
    }

    &-map {
      grid-column: 6 / 13;
      aspect-ratio: 1 / 1;

      @include media(lg) {
        top: gap(2.5);
        position: sticky;
      }

      [data-map-instance] {
        width: 100%;
        height: 100%;
        border-radius: var(--radius-xl);
      }
    }
  }

  &__loading {
    text-align: center;
    color: var(--icon-primary);
    display: none;

    &.active {
      display: block;
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    gap: gap(6);

    &.is-hidden {
      display: none;
    }
  }

  &__item {
    &.is-hidden {
      display: none;
    }
  }

  &__view-all {
    width: 100%;
    text-align: center;
    border: none !important;
    justify-content: center !important;

    .icon {
      color: var(--icon-primary);
    }

    &.is-hidden {
      display: none;
    }
  }
}

.map-form {
  display: flex;
  flex-direction: column;
  gap: gap(6);

  &__groups {
    display: flex;
    flex-direction: column;
    gap: gap(6);
  }

  &__group {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: gap(4);

    &-label {
      padding-top: gap(1.75);
    }

    &-fields {
      flex: 1;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: flex-start;
      row-gap: gap(2);
      column-gap: gap(0.5);
    }

    &-field {
      &--textfield {
        width: 100%;
      }
    }
  }
}