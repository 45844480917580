@import "../../../../styles/mixins";

.content-card {
  $self: &;

  background-color: #2F80ED;
  border-radius: gap(2);
  box-shadow: var(--shadow-default);
  display: flex;
  flex-direction: column;
  padding: var(--atom-xl) var(--molecule-md);
  color: var(--color-white-100);
  align-self: start;

  @include media(lg, max) {
    padding-top: gap(6);
    padding-bottom: gap(6);
  }

  &.active {
    #{ $self }__content {
      grid-template-rows: 1fr;
    }

    #{ $self }__button {
      &--open {
        display: none;
      }

      &--close {
        display: block;
      }
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: gap(4);

    p {
      text-decoration-thickness: 1px;
    }
  }

  &__content {
    display: grid;
    grid-template-rows: 0fr;
    transition: var(--transition-all);
  }

  &__content-inner {
    overflow: hidden;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: gap(2.5);
    padding-top: var(--atom-xl);
  }

  &__link {
    display: flex;
    align-items: center;
    gap: gap(2.5);
  }

  &__icon {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: gap(2);
    padding-top: var(--atom-xl);
  }

  &__icon-thumb {
    width: 100px;
    height: 72px;
    object-fit: contain;
  }

  &__button {
    &--close {
      display: none;
    }
  }
}
