@import "../../../../styles/mixins";

.c-search-result {
  &__inner {
    border-radius: gap(4);
    box-shadow: var(--shadow-default);
    overflow: hidden;

    background-color: var(--color-white-100);
    color: var(--color-grey-900);
  }

  &__content-container {
    display: flex;
    flex-direction: column;

    @include media(lg) {
      flex-direction: row;
      gap: gap(16);
    }
  }

  &__thumb-container {
    position: relative;
    overflow: hidden;
    order: -1;

    @include media(lg) {
      max-width: 300px;
      min-width: 300px;
    }
  }

  &__thumb {
    width: 100%;
    height: 100%;
    object-fit: cover;
    aspect-ratio: 300 / 185;

    @include media(lg, max) {
      min-height: 202px;
    }
  }

  &__wave {
    color: var(--color-white-100);
    position: absolute;
    width: calc(100% + 1px);
    top: 0;
    right: -1px;
    bottom: -1px;
    z-index: 1;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    @include media(lg) {
      width: auto;
      height: calc(100% + 1px);
    }
  }

  &__svg-1 {
    width: auto;
    height: 100%;

    @include media(lg, max) {
      display: none;
    }
  }

  &__svg-2 {
    width: 100%;
    height: auto;
    transform: rotate(180deg);

    @include media(lg) {
      display: none;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: gap(6);
    padding: 0 gap(6) gap(6) gap(6);

    @include media(lg) {
      gap: gap();
      padding: gap(8) gap(8) gap(8) 0;
      align-self: center;
    }
  }

  &__head {
    display: flex;
    flex-direction: column;
    gap: gap(6);

    @include media(lg) {
      gap: gap(2);
    }
  }

  &__description {
    @include line-clamp(3);
  }
}
