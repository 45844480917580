@import "../../../styles/mixins";

.filter-dropdown-trigger {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: gap(4);

  cursor: pointer;
  border: none;
  border-radius: var(--radius-sm);
  background-color: var(--bg-neutral-light);
  color: var(--bg-brand-dark);
  transition: var(--transition-color), var(--transition-background);
  padding: gap(2) gap(4);

  &--variant-secondary {
    color: var(--text-primary);
  }

  &:hover {
    color: var(--bg-brand-dark-hover);
    background-color: var(--bg-neutral-light-hover);
  }

  &:active, &.active {
    color: var(--bg-brand-dark-active);
    background-color: var(--bg-neutral-light-active);

    .icon {
      transform: rotate(180deg);
    }
  }

  .icon {
    color: var(--bg-brand-dark);
    transition: var(--transition-transform);
  }
}