@import "../../../styles/mixins";

.b-banner {
  padding: var(--block-top-bottom) 0;

  &__inner {
    border-radius: gap(4);
    box-shadow: var(--shadow-default);
    overflow: hidden;

    &--white {
      background-color: var(--color-white-100);
      color: var(--color-grey-900);
    }

    &--dark {
      background-color: var(--color-grey-900);
      color: var(--color-white-100);
    }
  }

  &__content-container {
    @include grid(1, 1, 2, 2, 2);
    gap: 0;
  }

  &__thumb-container {
    position: relative;
    overflow: hidden;

    &--right {
      order: 2;
    }
  }

  &__thumb {
    width: 100%;
    height: 100%;
    object-fit: cover;
    min-height: 370px;
    aspect-ratio: 584 / 360;

    @include media(lg, max) {
      min-height: 202px;
    }
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    gap: gap(4);
  }

  &__wave {
    position: absolute;
    width: calc(100% + 1px);
    z-index: 1;

    @include media(lg) {
      width: auto;
      height: calc(100% + 1px);
    }

    &--left {
      bottom: -1px;
      right: -1px;

      @include media(lg) {
        top: 0;
      }
    }

    &--right {
      top: 0;
      left: -1px;
      transform: rotate(180deg);

      @include media(lg) {
        bottom: -1px;
      }
    }

    &--white {
      color: var(--color-white-100);
    }

    &--dark {
      color: var(--color-grey-900);
    }
  }

  &__svg-1 {
    width: auto;
    height: 100%;

    @include media(lg, max) {
      display: none;
    }
  }

  &__svg-2 {
    width: 100%;
    height: 100%;
    transform: rotate(180deg);

    @include media(lg) {
      display: none;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: var(--molecule-md);
    padding: gap(6);

    @include media(lg) {
      padding: var(--molecule-md) gap(20);
      align-self: center;
    }
  }

  &__head {
    display: flex;
    flex-direction: column;
    gap: var(--molecule-sm);
  }
}
