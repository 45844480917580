@import "../../../styles/mixins";

.textarea {
  $self: &;
  position: relative;
  border-radius: var(--radius-sm);
  border: 1px solid var(--border-primary);
  background-color: var(--bg-neutral-light);
  position: relative;

  &--error {
    border-color: var(--border-error);
  }

  &__data-entry {
    resize: none;
    width: 100%;
    min-height: gap(68.5);
    padding: gap(8) gap(4) gap(3);
    border: none;
    outline: none;
    border-radius: var(--radius-sm);

    &:placeholder-shown {
      & ~ #{ $self }__label {
        font-size: 16px;
        top: gap(2.25);
      }
    }

    &::placeholder {
      opacity: 0;
      visibility: hidden;
    }
  }

  &:hover {
    border-color: var(--border-brand-1-hover);
  }

  &:focus-within {
    border-color: var(--border-brand-1-active);

    #{ $self }__label {
      top: gap();
      font-size: 10px;
    }
  }

  &__label {
    color: var(--text-placeholder);
    position: absolute;
    left: gap(4);
    top: gap();
    font-size: 10px;
    transition: var(--transition-all);
  }

  &__error {
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    width: 100%;
    color: var(--text-error);
  }
}