@import "../../../../styles/mixins";

.map-card {
  padding: gap(4) gap(6);
  border-radius: var(--radius-md);
  box-shadow: var(--shadow-map-card);
  background-color: var(--bg-neutral-light);
  border: 1px solid transparent;

  display: flex;
  flex-direction: column;
  gap: gap(2);

  &.active {
    border-color: var(--border-primary);
  }

  &__head {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: gap();

    .icon {
      font-size: 18px;
      padding-top: gap(1.5);
      color: var(--icon-primary);
    }

    &-name {
      color: var(--text-primary);
    }
  }

  &__tags {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: gap(2.5);
    flex-wrap: wrap;
  }

  &__content {
    &-list {
      display: inline-flex;
      flex-direction: column;
      gap: gap(2);
    }

    &-item {
      display: inline-flex;
      align-items: flex-start;
      justify-content: flex-start;
      gap: gap(2);
      color: var(--text-primary);
      transition: var(--transition-color);

      &--link {
        &:hover {
          color: var(--text-primary-hover);
        }

        &:active {
          color: var(--text-primary-active);
        }
      }

      .icon {
        font-size: 14px;
        padding-top: gap(0.75);
        color: var(--icon-primary);
      }
    }
  }
}