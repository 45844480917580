@font-face {
  font-display: swap;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/mulish-v13-latin_latin-ext-regular.woff2') format('woff2'),
  url('../assets/fonts/mulish-v13-latin_latin-ext-regular.ttf') format('truetype');
}
@font-face {
  font-display: swap;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 500;
  src: url('../assets/fonts/mulish-v13-latin_latin-ext-500.woff2') format('woff2'),
  url('../assets/fonts/mulish-v13-latin_latin-ext-500.ttf') format('truetype');
}
@font-face {
  font-display: swap;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 900;
  src: url('../assets/fonts/mulish-v13-latin_latin-ext-900.woff2') format('woff2'),
  url('../assets/fonts/mulish-v13-latin_latin-ext-900.ttf') format('truetype');
}
@font-face {
  font-display: swap;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  src: url('../assets/fonts/mulish-v13-latin_latin-ext-700.woff2') format('woff2'),
  url('../assets/fonts/mulish-v13-latin_latin-ext-700.ttf') format('truetype');
}
