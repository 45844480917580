@import "../../../../styles/mixins";

.c-milestone {
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: gap(2);
  background-color: var(--color-white-100);
  box-shadow: var(--shadow-default);

  &__inner {
    position: relative;
  }

  &__thumb {
    aspect-ratio: 320 / 240;
    object-fit: cover;
  }

  &__wave {
    width: 100%;
    height: auto;
    position: absolute;
    bottom: -2px;
    left: 0;
    color: var(--color-white-100);
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: gap(4);
    justify-content: flex-end;
    padding: 0 gap(4) gap(4) gap(4);
  }

  &__label {
    padding-top: gap();
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: gap(2);
  }
}
