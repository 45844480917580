@import "../../../styles/mixins";

.b-search-results {
  $self: &;

  padding: gap(8) 0;

  @include media(lg) {
    padding: gap(19) 0;
  }

  &__container {
    @include grid(1, 1, 12, 12, 12);
    grid-gap: gap(8);

    @include media(lg) {
      grid-gap: gap(19);
    }
  }

  &__title {
    color: var(--text-heading);

    @include media(lg) {
      grid-column: span 12;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: gap(16);

    @include media(lg) {
      grid-column: span 10;
      gap: gap(10);
    }
  }

  &__item {}
}
