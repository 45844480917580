@import "../../../styles/mixins";

.b-hero-banner {
  $self: &;

  background-color: var(--color-white-100);

  @include media(lg, max) {
    padding-top: gap(8);
  }

  &--type-logo-large,
  &--type-logo-small {
    #{ $self }__thumb-container {
      mask-image: url('../../../assets/images/industry-card-mask.svg');
    }
  }

  &--type-logo-large {
    #{ $self }__thumb-container {
      @include media(lg) {
        width: 100%;
        height: 478px;
        transform: translateY(gap(9)) translateX(0);
      }
    }
  }

  &--type-logo-small {
    #{ $self }__thumb-container {
      @include media(lg) {
        width: 100%;
        height: 343px;
        transform: translateY(gap(9)) translateX(0);
      }
    }
  }

  &--type-image-large,
  &--type-image-small {
    @include media(lg) {
      #{ $self }__thumb-inner {
        grid-column-start: 7;
      }
    }

    #{ $self }__thumb-container {
      mask-image: url('../../../assets/images/hero-banner-mask.svg');

      @include media(lg, max) {
        mask-image: url('../../../assets/images/industry-card-mask.svg');
      }

      @include media(lg) {
        transform: translateX(gap(20));
        border-top-left-radius: 50%;
        width: 100%;
        height: 100%;
      }
    }
  }

  &--type-image-large {
    @include media(lg) {
      #{ $self }__head {
        padding: gap(30) 0;
      }
    }
  }

  &__content {
    padding: 0 gap(6);
    border-radius: gap(4);
    grid-gap: gap(6);
    overflow: hidden;
    box-shadow: var(--shadow-default);
    position: relative;

    @include grid(1, 1, 12, 12, 12);

    @include media(lg) {
      padding: 0 gap(20);
    }

    &--white {
      background-color: var(--color-white-100);
      color: var(--color-grey-900);
    }

    &--dark {
      background-color: var(--color-grey-900);
      color: var(--color-white-100);
    }

    &--blue {
      background-color: var(--color-blue);
      color: var(--color-white-100);
    }
  }

  &__head {
    align-self: center;
    padding: gap(12) 0;
    display: flex;
    flex-direction: column;
    gap: gap(4);

    @include media(lg) {
      grid-column: span 6;
    }
  }

  &__thumb-inner {
    position: relative;

    @include media(lg) {
      grid-column-start: 8;
      grid-column-end: 13;
      width: calc(100% + 80px);
    }

    @include media(lg, max) {
      display: flex;
      justify-content: flex-end;
      width: calc(100% + 24px);
    }
  }

  &__thumb-container {
    mask-repeat: no-repeat;
    mask-size: auto 100%;
    width: 100%;
    height: 100%;
    transform: translateX(50%);
    //width: 100%;
    //height: 100%;
    //transform: translateX(50%);
    //mask-repeat: no-repeat;
    //mask-size: auto 100%;
    //
    //@include media(lg) {
    //  width: 1000px;
    //  height: 478px;
    //  transform: translateY(gap(9)) translateX(0);
    //}
  }

  &__thumb {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.b-hero-banner-stats {
  width: 100%;
  padding: gap(8) gap(6);
  @include grid(1, 1, 3, 3, 3);
  grid-gap: gap(6);
  position: relative;
  margin-top: gap(-22);

  @include media(lg) {
    padding: gap(20);
    margin-top: gap(-32);
  }

  &__item {
    background-color: var(--color-white-100);
    box-shadow: var(--shadow-default);
    border-radius: gap(2);
    padding: gap(2);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: var(--text-primary);

    @include media(lg) {
      padding: gap(12) gap(6);
      gap: gap();
    }
  }

  &__count {
    color: var(--text-brand);
  }
}
