@import "../../../styles/mixins";

.button {
  $self: &;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--atom-sm);
  text-decoration: none;
  padding-top: var(--atom-md);
  padding-bottom: var(--atom-md);
  border-radius: var(--radius-sm);
  transition: var(--transition-color), var(--transition-background), var(--transition-border);

  //&:focus {
  //  outline-offset: var(--spacing-0-5);
  //  outline: var(--spacing-0-5) solid var(--color-support-focus-300);
  //  box-shadow: var(--shadow-button);
  //}

  &.hidden {
    visibility: hidden;
    pointer-events: none;
  }

  &[disabled],&.disabled {
    cursor: not-allowed;
  }

  &--variant-primary,
  &--variant-secondary {
    padding-inline: var(--atom-lg);
    border-width: gap(0.5);
    border-style: solid;
  }

  &--variant-ghost {
    border: gap(0.5) solid transparent;
    background-color: transparent;
    padding-inline: var(--atom-sm);
  }

  &--variant-icon {
    position: relative;
    padding-inline: var(--atom-md);
    border-radius: var(--radius-full);
    border: none;
    background-color: transparent;
    width: gap(10);
    height: gap(10);
    justify-content: center;
  }


  &--variant-primary#{ & }--surface-light {
    color: var(--text-primary-inverted);
    border-color: var(--bg-brand-dark);
    background-color: var(--bg-brand-dark);

    &:hover {
      border-color: var(--bg-brand-dark-hover);
      background-color: var(--bg-brand-dark-hover);
    }

    &:active,&.active {
      border-color: var(--bg-brand-dark-active);
      background-color: var(--bg-brand-dark-active);
    }

    &[disabled],&.disabled {
      color: var(--text-disabled);
      border-color: var(--bg-disabled-1);
      background-color: var(--bg-disabled-1);
    }
  }

  &--variant-primary#{ & }--surface-dark {
    color: var(--text-brand);
    border-color: var(--bg-brand-dark-inverted);
    background-color: var(--bg-brand-dark-inverted);

    &:hover {
      border-color: var(--bg-brand-dark-inverted-hover);
      background-color: var(--bg-brand-dark-inverted-hover);
    }

    &:active,&.active {
      border-color: var(--bg-brand-dark-inverted-active);
      background-color: var(--bg-brand-dark-inverted-active);
    }

    &[disabled],&.disabled {
      color: var(--text-disabled-inverted);
      border-color: var(--bg-disabled-1-inverted);
      background-color: var(--bg-disabled-1-inverted);
    }
  }

  &--variant-secondary#{ & }--surface-light {
    color: var(--text-primary);
    border-color: var(--border-brand-1);
    background-color: var(--bg-neutral-light);

    &:hover {
      border-color: var(--border-brand-1-hover);
      background-color: var(--bg-neutral-light-hover);
    }

    &:active,&.active {
      border-color: var(--border-brand-1-active);
      background-color: var(--bg-neutral-light-active);
    }

    &[disabled],&.disabled {
      color: var(--text-disabled);
      border-color: var(--border-disabled);
      background-color: var(--bg-disabled-1);
    }
  }

  &--variant-secondary#{ & }--surface-dark {
    color: var(--text-primary-inverted);
    border-color: var(--border-primary-inverted);
    background-color: var(--bg-neutral-light-inverted);

    &:hover {
      background-color: var(--bg-neutral-light-inverted-hover);
    }

    &:active,&.active {
      background-color: var(--bg-neutral-light-inverted-active);
    }

    &[disabled],&.disabled {
      border-color: var(--border-disabled-inverted);
      background-color: var(--bg-disabled-1-inverted);
    }
  }

  &--variant-ghost#{ & }--surface-light#{ & }--type-primary {
    color: var(--text-primary);

    i {
      color: var(--text-brand);
    }

    &:hover {
      color: var(--text-brand-hover);

      i {
        color: var(--text-brand-hover);
      }
    }

    &:active,&.active {
      color: var(--text-brand-active);

      i {
        color: var(--text-brand-active);
      }
    }
  }

  &--variant-ghost#{ & }--surface-light#{ & }--type-secondary {
    color: var(--text-secondary);

    &:hover {
      color: var(--text-secondary-hover);
    }

    &:active,&.active {
      color: var(--text-secondary-active);
    }
  }

  &--variant-ghost#{ & }--surface-dark#{ & }--type-primary {
    color: var(--text-primary-inverted);
  }

  &--variant-ghost#{ & }--surface-dark#{ & }--type-secondary {
    color: var(--text-secondary-inverted);
  }

  &--variant-ghost#{ & }--surface-light {
    &:hover {
      background-color: var(--bg-neutral-light-hover);
    }

    &:active,&.active {
      background-color: var(--bg-neutral-light-hover);
    }

    &[disabled],&.disabled {
      color: var(--text-disabled);
    }
  }

  &--variant-ghost#{ & }--surface-dark {
    &:hover {
      background-color: var(--bg-neutral-light-inverted-hover);
    }

    &:active,&.active {
      background-color: var(--bg-neutral-light-inverted-active);
    }

    &[disabled],&.disabled {
      color: var(--text-disabled-inverted);
    }
  }

  &--variant-icon#{ & }--surface-light {
    color: var(--color-white-100);
    background-color: var(--bg-brand-dark);

    &:hover {
      color: var(--color-white-80);
      background-color: var(--bg-brand-dark-hover);
    }

    &:active,&.active {
      color: var(--color-white-70);
    }

    &[disabled],&.disabled {
      color: var(--icon-disabled);
    }
  }

  &--variant-icon#{ & }--surface-dark {
    color: var(--color-white-100);

    &:hover {
      color: var(--icon-primary-inverted-hover);
    }

    &:active,&.active {
      color: var(--icon-primary-inverted-active);
    }

    &[disabled],&.disabled {
      color: var(--icon-disabled-inverted);
    }
  }

  &--block {
    width: 100%;
  }
}
