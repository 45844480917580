@import "../../../styles/mixins";

.hero-full-image {
  position: relative;
  aspect-ratio: 375 / 662;
  display: flex;
  align-items: center;
  justify-content: center;

  @include media(lg) {
    aspect-ratio: 1440 / 662;
  }

  &:after, &:before {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  &:before {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.00) 100%);
  }

  &:after {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.00) 100%);
  }

  &__heading {
    text-align: center;
    position: relative;
    z-index: 5;
    text-transform: uppercase;
    color: var(--text-primary-inverted);
  }

  &__thumb-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }
}