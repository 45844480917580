@import "../../../styles/mixins";

.b-hero {
  background-color: var(--color-grey-900);
  position: relative;
  overflow: hidden;

  &__inner {
    @include grid(1, 1, 12, 12, 12);
    gap: gap(6);

    @include media(lg, max) {
      padding-top: gap(20);
    }

    @include media(lg) {
      padding: 0 gap(10);
      gap: 0;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: gap(13);
    color: var(--color-white-100);

    @include media(lg) {
      grid-column: span 4;
      align-self: center;
    }
  }

  &__head {
    display: flex;
    flex-direction: column;
    gap: var(--molecule-sm);
  }

  &__gallery {
    width: calc(100% + 16px);

    @include media(lg) {
      width: unset;
      grid-column-start: 6;
      grid-column-end: 13;
    }
  }

  &__gallery-inner {
    background-color: var(--color-grey-900);
    @include grid(8, 8, 8, 8, 8);
    gap: gap(4);

    @include media(lg) {
      width: 800px;
      gap: gap(8);
    }
  }

  &__thumb {

    &--inner {
      &-first {
        grid-column: span 5;
      }

      &-second {
        grid-column: span 3;
        align-self: end;
      }

      &-third {
        grid-column: span 3;
      }

      &-fourth {
        grid-column: span 5;
      }
    }

    &--first {
      aspect-ratio: 1 / 1;
      object-fit: cover;
      border-bottom-left-radius: gap(4);
      border-bottom-right-radius: gap(4);

      @include media(lg, max) {
        border-radius: gap(4);
      }
    }

    &--second {
      width: 100%;
      object-fit: cover;
      border-top-left-radius: gap(4);
      border-bottom-left-radius: gap(4);

      @include media(lg, max) {
        border-radius: gap(4);
      }
    }

    &--third {
      width: 100%;
      height: 100%;
      max-height: gap(20);
      object-fit: cover;
      border-top-left-radius: gap(4);
      border-top-right-radius: gap(4);

      @include media(md) {
        max-height: 200px;
      }
    }

    &--fourth {
      width: 100%;
      height: 100%;
      max-height: gap(20);
      object-fit: cover;
      border-top-left-radius: gap(4);

      @include media(md) {
        max-height: 200px;
        border-top-right-radius: gap(4);
      }
    }
  }
}
