@import "../../../../styles/mixins";

.c-article {
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: gap(4);

  &--white {
    background-color: var(--color-white-100);
    color: var(--color-white-100)
  }

  &--dark {
    background-color: var(--color-grey-900);
    color: var(--color-grey-900);
  }

  &--blue {
    background-color: var(--color-blue);
    color: var(--color-blue);
  }

  &__inner {
    position: relative;
    aspect-ratio: 404 / 304;
    width: 100%;
  }

  &__thumb {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__wave {
    width: 100%;
    height: auto;
    position: absolute;
    bottom: -2px;
    left: 0;
  }

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: gap(4);
    justify-content: flex-end;
    padding: gap(4);

    &--white {
      color: var(--color-grey-900);
    }

    &--dark {
      color: var(--color-white-100);
    }

    &--blue {
      color: var(--color-white-100);
    }
  }

  &__title {
    @include line-clamp(2);
  }

  &__description {
    @include line-clamp(3);
  }

  &__time {
    display: flex;
    align-items: center;
    gap: gap(4);

    &--white {
      color: #00000080;
    }

    &--dark {
      color: #FFFFFFB2;
    }

    &--blue {
      color: #FFFFFFB2;
    }
  }
}
