@import "../../../../../styles/mixins";

.desktop-submenu-grid {

  &__items {
    @include grid(4, 4, 4, 4, 4);
    grid-gap: gap(4);
  }
}

.desktop-submenu-grid-link {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  color: var(--text-brand);
  text-decoration: none;
  padding: var(--atom-xl) var(--molecule-xs) 0 var(--atom-xl);
  border-radius: var(--radius-md);
  border: 1px solid var(--border-primary);
  background-color: var(--bg-neutral-light);
  overflow: hidden;
  transition: var(--transition-color), var(--transition-border);

  &:hover {
    color: var(--text-brand-hover);
    border-color: var(--border-primary-hover);
  }

  &:active {
    color: var(--text-brand-active);
    border-color: var(--border-primary-active);
  }

  &__thumb {
    display: flex;
    justify-content: flex-end;

    &-mask {
      width: 203px;
      height: 94px;
      transform: translateX(calc(50% + var(--molecule-xs)));
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}