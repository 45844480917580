@import "../../../styles/mixins";

.b-usp {
  $self: &;

  background-color: var(--color-white-100);
  padding: var(--block-top-bottom) 0;

  &--type-logo-top {
    #{ $self }__list {
      @include media(lg) {
        gap: gap(8);
      }
    }

    #{ $self }__item {
      flex-direction: column;
      align-items: center;
      gap: gap(6);
      padding: gap(6) gap(4);
      color: var(--text-primary);
    }

    #{ $self }__icon {
      min-width: gap(20);
      width: gap(20);
      height: gap(20);
    }

    #{ $self }__title {
      text-transform: uppercase;
      color: var(--bg-brand-dark);
      text-align: center;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: gap(20);
  }

  &__head {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    gap: gap(2);
    text-align: center;
    width: 100%;
    max-width: gap(180);
    color: var(--text-primary);

    @include media(lg) {
      gap: gap(4);
    }
  }

  &__list {
    @include grid(1, 1, 3, 3, 3);
    gap: gap(6);
  }

  &__item {
    display: flex;
    gap: gap(4);
  }

  &__icon {
    min-width: gap(10);
    width: gap(10);
    height: gap(10);
    object-fit: cover;

    @include media(lg) {
      min-width: gap(16);
      width: gap(16);
      height: gap(16);
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: gap(3);
    color: var(--color-grey-900);
  }
}
