@import "../../../styles/mixins";

.video {

  &--background-white {
    background-color: var(--section-neutral-light);
  }

  &--background-light-blue {
    background-color: var(--section-brand-light);
  }

  &--background-dark {
    background-color: var(--section-neutral-dark);
  }

  &__inner {
    padding: gap(20) 0;
  }

  &__content {
    position: relative;
    width: 100%;
    object-fit: cover;
    border-radius: var(--radius-xl);
    overflow: hidden;
  }

  .plyr {
    --plyr-color-main: var(--text-brand);

    &--stopped {
      .plyr__controls {
        opacity: 0 !important;
      }
    }

    &__poster {
      background-size: cover;
    }

    &__control {
      &--overlaid {
        display: none !important;
      }
    }
  }
}

.video-play-button {
  cursor: pointer;
  padding: var(--atom-xs);
  border-radius: var(--radius-full);
  background-color: var(--color-white-100);
  box-shadow: var(--shadow-300);

  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  align-items: center;
  gap: gap(3);

  &-icon {
    width: gap(10);
    min-width: gap(10);
    height: gap(10);
    border-radius: var(--radius-full);
    background-color: var(--bg-brand-dark);
    color: var(--color-white-100);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-content {
    padding-right: var(--atom-lg);
    display: flex;
    flex-direction: column;
  }

  &.is-hidden {
    display: none;
  }
}