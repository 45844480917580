@import "../../../styles/mixins";

.b-text-awards {
  background-color: var(--section-brand-light);
  padding: gap(8) 0;
  color: var(--text-primary);

  @include media(lg) {
    padding: gap(20) 0;
  }

  &__container {
    @include grid(1, 1, 2, 2, 2);
    grid-gap: gap(8);

    @include media(lg) {
      grid-gap: gap(20);
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: gap(6);

    @include media(lg) {
      gap: gap(10);
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    gap: gap(2);
  }

  &__link {
    color: var(--text-primary);
  }
}
