@import "../../../styles/mixins";

.contact-form {

  &__inner {
    display: flex;
    flex-direction: column-reverse;

    @include media(lg) {
      @include grid(12, 12, 12, 12, 12);
      grid-gap: gap(6);
      padding: var(--spacing-16) 0;
    }
  }

  &__contact {
    display: flex;
    flex-direction: column;
    gap: gap(6);
    padding: gap(8) 0;

    &-title {
      color: var(--text-heading);
    }

    &-text {
      color: var(--text-primary);
    }

    @include media(lg) {
      grid-column: 10 / 13;
      padding: 0;
    }
  }

  &__form {

    display: flex;
    flex-direction: column;
    gap: gap(8);
    padding-bottom: gap(8);

    @include media(lg) {
      grid-column: 1 / 9;
      padding: var(--spacing-16);
      box-shadow: var(--shadow-300);
      border-radius: var(--radius-xl);
      border: 1px solid var(--border-primary);
    }

    &-head {
      display: flex;
      flex-direction: column;
      gap: gap(4);
    }

    &-groups {
      display: flex;
      flex-direction: column;
      gap: gap(8);
    }

    &-group {
      display: flex;
      flex-direction: column;
      gap: gap(6);

      &-title {
        color: var(--text-primary);
      }

      &-fields {
        @include grid(1, 1, 2, 2, 2);
        grid-gap: gap(4);
      }

      &-field {
        @include media(lg) {
          grid-column: span 2;

          &--textfield {
            grid-column: span 1;
          }
        }

        &-items {
          display: flex;
          flex-direction: column;
          gap: gap(2);
        }

        &-item {
          display: flex;
          flex-direction: column;
          gap: gap(2);
        }
      }
    }

    &-title {
      color: var(--text-heading);
    }

    &-text {
      color: var(--text-secondary);
    }
  }
}

.contact-form-field-children {
  padding: gap(6);
  border-radius: gap(2);
  background-color: var(--color-grey-100);

  @include media(lg) {
    margin-left: gap(8);
  }

  display: flex;
  flex-direction: column;
  gap: gap(4);

  &__label {
    color: var(--text-primary);
  }

  &__fields {
    display: flex;
    flex-direction: column;
    gap: gap(4);

    @include media(lg) {
      width: 100%;
      max-width: gap(94);
    }
  }

  &__field {
    &-items {
      display: flex;
      flex-direction: column;
      gap: gap(2);
    }
  }
}