@import "../../../../../styles/mixins";

.desktop-submenu-slider {
  &__inner {
    @include grid(12, 12, 12, 12, 12);
    grid-gap: gap(6);
  }

  &__items {
    grid-column: 1 / 8;
    display: flex;
    flex-direction: column;
    gap: gap(2);
  }

  &__quick-links {
    grid-column: 8 / 13;
  }

  &__list {
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }
}

.desktop-submenu-slider-link {
  display: block;
  padding: gap() 0;
  text-decoration: none;
  color: var(--text-brand);
  transition: var(--transition-color);

  &:hover {
    color: var(--text-brand-hover);
  }

  &:active {
    color: var(--text-brand-active);
  }
}

.desktop-submenu-slider-card {
  position: relative;

  &:after {
    display: block;
    content: "";
    position: absolute;
    inset: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.60) 100%);
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__content {
    position: relative;
    padding: gap(8) gap(12) gap(12);
    display: flex;
    flex-direction: column;
    gap: gap(6);
    z-index: 5;
  }

  &__logo {
    width: auto;
    height: gap(16);
  }

  &__controls {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: gap(3);

    &-count {
      color: var(--color-white-100);
    }
  }

  &__heading {
    color: var(--color-white-100);
  }

  &__button {
    padding-top: gap(2);
  }

  &__slider-timer {
    width: 100%;
    max-width: gap(25);
  }
}

.slider-timer {
  height: gap(0.5);
  width: 0;
  background-color: var(--bg-brand-dark);
}