@import "../../../../styles/mixins";

.c-brand {
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: gap(4);
  border: 1px solid #CFEEFC;
  box-shadow: var(--shadow-default);
  background-color: var(--color-white-100);

  &__thumb {
    width: 100%;
    aspect-ratio: 416 / 200;
    object-fit: cover;
  }

  &__content {
    padding: gap(6) gap(4);
    display: flex;
    flex-direction: column;
    gap: gap(2);

    @include media(lg) {
      padding: gap(6) gap(8);
      gap: gap(4);
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: gap(2);
    color: var(--text-primary);
  }

  &__tag {
    @include media(lg, max) {
      display: none;
    }
  }
}
