@import "../../../styles/mixins";

.breadcrumbs {
  $self: &;

  &__inner {
    padding: var(--molecule-xs) 0;
  }

  &__items {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__item {
    &:not(:last-of-type) {
      #{ $self }__link {
        color: var(--text-primary);

        &:hover {
          color: var(--text-primary-hover);
        }

        &:active {
          color: var(--text-primary-active);
        }
      }
    }
  }

  &__link {
    position: relative;
    display: flex;
    align-items: center;
    gap: var(--atom-sm);
    padding: var(--atom-xs) var(--atom-sm);
    color: var(--icon-disabled);

    &-icon-home {
      color: var(--icon-primary);
      font-size: 18px !important;
      transition: var(--transition-color);

      &:hover {
        color: var(--icon-primary-hover);
      }

      &:active {
        color: var(--icon-primary-active);
      }
    }

    &-icon-arrow {
      color: var(--icon-secondary);
      font-size: 14px !important;
    }
  }
}