@import "../../../styles/mixins";

.radio {
  $self: &;
  position: relative;

  &:hover {
    #{ $self }__box {
      border-color: var(--border-primary-hover);
    }
  }

  &--error {
    #{ $self }__box {
      border-color: var(--border-error);
    }
  }

  input[type="radio"] {
    width: 0;
    height: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -5;
    opacity: 0;

    &:checked {
      & ~ #{ $self }__content {
        #{ $self }__box {
          border-color: var(--border-brand-1);

          &:after {
            transform: scale(1);
          }
        }
      }
    }

    &:disabled {
      & ~ #{ $self }__content {
        #{ $self }__box {
          border-color: var(--border-disabled);
          background-color: var(--bg-disabled-1);
          color: var(--icon-disabled);

          &:after {
            background-color: var(--icon-disabled);
          }
        }

        #{ $self }__label {
          color: var(--text-disabled);
        }
      }
    }
  }

  &__content {
    cursor: pointer;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--spacing-2)
  }

  &__box {
    width: gap(6);
    min-width: gap(6);
    height: gap(6);
    border-radius: var(--radius-full);
    border: 1px solid var(--border-primary);
    background-color: var(--color-white-100);

    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--icon-primary-inverted);
    transition: var(--transition-border);
    position: relative;

    &:after {
      display: block;
      content: "";
      width: gap(3);
      height: gap(3);
      min-width: gap(3);
      background-color: var(--bg-brand-dark);
      border-radius: var(--radius-full);
      transform: scale(0);
      transition: var(--transition-transform);
    }
  }

  &__label {
    color: var(--text-primary);

    a {
      text-decoration: underline;
      transition: var(--transition-color);

      &:hover {
        color: var(--text-brand-hover);
      }
    }
  }

  &__error {
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    width: 100%;
    color: var(--text-error);
  }
}