@import "../../../styles/mixins";

.dropdown-item {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: var(--atom-sm);
  text-decoration: none;
  color: var(--text-primary);
  padding: var(--atom-sm) var(--atom-lg);
  background-color: var(--bg-neutral-light);
  transition: var(--transition-all);

  @include media(lg) {
    gap: gap(20);
  }

  &--active {
    color: var(--text-brand);
  }

  &:hover {
    background-color: var(--bg-neutral-light-hover);
  }

  &[disabled],&.disabled {
    color: var(--text-disabled);
    background-color: var(--bg-disabled-1);
  }
}