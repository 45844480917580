@import "../../../../../styles/mixins";

.desktop-submenu-default {
  position: relative;

  &__content {
    width: 100%;
    max-width: 33%;
    display: flex;
    flex-direction: column;
    gap: gap(4);
  }

  &__category {
    color: var(--text-secondary);
  }

  &__links {
    display: flex;
    flex-direction: column;
    gap: gap(4);
    padding-bottom: gap(10);
  }

  &__link {
    &:hover {
      & > .desktop-submenu-default-category-childrens {
        opacity: 1;
        visibility: visible;
        pointer-events: initial;
      }

      & > .desktop-submenu-default-category-link {
        background-color: var(--bg-info);

        .desktop-submenu-default-category-link__label {
          color: var(--text-brand-active);
        }

        .icon {
          opacity: 1;
        }
      }
    }
  }

  &__childrens {
    position: absolute;
    left: 33%;
    top: 0;
    right: 0;
  }
}

.desktop-submenu-default-category-link {
  $self: &;
  display: flex;
  gap: gap(4);
  padding: gap(3) gap(4);
  text-decoration: none;
  transition: var(--transition-color), var(--transition-background);

  &:hover {
    background-color: var(--bg-info);

    #{ $self }__label {
      color: var(--text-brand-active);
    }

    .icon {
      opacity: 1;
    }
  }

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: gap();
  }

  &__label {
    color: var(--text-heading);
    display: flex;
    justify-content: space-between;
    align-items: center;

    .icon {
      opacity: 0;
      color: var(--icon-primary);
      transition: var(--transition-opacity);
    }
  }

  &__meta {
    color: var(--text-primary);
  }
}

.desktop-submenu-default-category-childrens {
  $self: &;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  $self: &;
  border-left: 1px solid var(--border-primary);
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  left: calc(100% / (var(--total-deep)));
  display: flex;
  flex-direction: column;
  gap: gap(6);
  transition: var(--transition-opacity);

  &--first {
    right: 0;
    left: 33%;
    width: unset;

    & > #{ $self }__list {
      width: calc(100% / var(--total-deep));
    }
  }

  &__label {
    padding: 0 gap(4);
    color: var(--text-secondary);
  }

  &__list {
    width: calc(100% / (var(--total-deep) - 1));
    display: flex;
    flex-direction: column;
    gap: gap(3);
    flex: 1;
    overflow-y: auto;

    &-item {
      &:hover {
        & > .desktop-submenu-default-category-childrens {
          opacity: 1;
          visibility: visible;
          pointer-events: initial;
        }

        & > #{ $self }__link {
          color: var(--text-brand-active);
          background-color: var(--bg-info);

          .icon {
            opacity: 1;
          }
        }
      }
    }
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--color-black-100);
    padding: gap(2) gap(4);
    transition: var(--transition-color), var(--transition-background);

    &:hover {
      color: var(--text-brand-active);
      background-color: var(--bg-info);

      .icon {
        opacity: 1;
      }
    }

    .icon {
      opacity: 0;
      color: var(--icon-primary);
      transition: var(--transition-opacity);
    }
  }
}