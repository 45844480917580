@import "../../../styles/mixins";

.b-quote {
  background-color: var(--bg-brand-light);
  padding: gap(8) 0;

  @include media(lg) {
    padding: gap(20) 0;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: gap(2);
    max-width: gap(256);
    width: 100%;
    margin: 0 auto;
    text-align: center;
    color: var(--text-primary);
  }

  &__quote {
    padding-bottom: gap(2);
  }
}
