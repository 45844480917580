.ui-wave {
  width: 100%;
  display: flex;
  position: absolute;
  bottom: -1px;
  left: 0;
  color: var(--color-white-100);

  &__svg {
    width: 100%;
    height: auto;
  }
}
