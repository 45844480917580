@import "../../../../../styles/mixins";

.header-desktop {
  &__top {
    padding-top: var(--molecule-sm);

    &-inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: var(--molecule-md);
    }

    &-left {
      flex: 1;
      display: flex;
      align-items: center;
      gap: var(--molecule-md);
    }

    &-right {
      display: flex;
      gap: var(--molecule-sm);
    }
  }

  &__bottom {
    position: relative;

    &-inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: var(--block-lg);
    }

    &-left {
      padding-top: var(--molecule-xs);
    }
  }

  &__logo {
    position: relative;
    width: 100%;
    max-width: gap(30.5);
  }

  &__search-form {
    width: 100%;
    max-width: gap(107);
  }

  &__quick-links {
    display: flex;
    gap: var(--molecule-xs);
  }

  &__navigation {

    padding-top: var(--spacing-4);

    &-list {
      display: flex;
    }

    &-item {
      &:hover {
        & > .desktop-submenu {
          --desktop-submenu-status: opened;
          opacity: 1;
          pointer-events: initial;
          visibility: visible;

          .desktop-submenu__overlay {
            display: block;
          }
        }

        & > .navigation-link {
          .icon {
            transform: rotate(180deg);
          }

          &:after {
            background-color: var(--bg-brand-dark);
          }
        }
      }

      .navigation-link {
        padding-inline: gap(3);
      }

      &:first-of-type {
        .navigation-link {
          padding-left: 0;
        }
      }

      &:last-of-type {
        .navigation-link {
          padding-right: 0;
        }
      }
    }
  }
}

.desktop-submenu {
  --desktop-submenu-status: closed;
  background-color: var(--color-white-100);
  opacity: 0;
  visibility: visible;
  pointer-events: none;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 1;
  box-shadow: inset var(--shadow-300);

  &__overlay {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100vh;
    pointer-events: none;
    background-color: var(--color-black-50);;
  }

  &__inner {
    padding: gap(10) 0;
  }
}