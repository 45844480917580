@import "../../../styles/mixins";

.footer {
  display: flex;
  flex-direction: column;
}

.footer-top {
  $self: &;
  padding: var(--block-top-bottom) 0;
  background-color: var(--section-neutral-dark);

  &__inner {
    @include grid(1, 1, 12, 12, 12);

    @include media(lg) {
      grid-gap: gap(6);
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: var(--molecule-md);

    @include media(lg) {
      grid-column: 1 / 5;
    }

    &-top {
      display: flex;
      flex-direction: column;
      gap: var(--molecule-md);
    }

    &-bottom {
      display: none;

      @include media(lg) {
        display: block;
      }
    }

    &-description {
      color: var(--text-primary-inverted);
    }

    &-socials {
      display: flex;
      gap: var(--atom-sm);
    }
  }

  &__navigate {
    @include grid(1, 1, 3, 3, 3);
    grid-gap: var(--grid-gutter);

    @include media(lg) {
      grid-gap: 0;
      grid-column: 6 / 13;
    }

    &-item {
      &.active {
        #{ $self }__navigate-group-trigger {
          .icon {
            transform: rotate(180deg);
          }
        }

        #{ $self }__navigate-links-wrapper {
          grid-template-rows: 1fr;
        }
      }
    }

    &-group-trigger {
      .icon {
        transition: var(--transition-all);

        @include media(lg) {
          display: none;
        }
      }

      @include media(lg) {
        cursor: initial !important;
        &:hover, &:active {
          background-color: transparent !important;
        }
      }
    }

    &-links-wrapper {
      display: grid;
      grid-template-rows: 0fr;
      transition: var(--transition-all);

      @include media(lg) {
        display: block;
      }
    }

    &-links {
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }
  }

  &__language {
    text-align: center;

    @include media(lg) {
      display: none;
    }
  }
}

.footer-bottom {
  padding: var(--atom-sm) 0;
  background-color: var(--section-neutral-light);

  &__inner {
    @include grid(1, 1, 12, 12, 12);
    grid-gap: gap(6);
  }

  &__label {
    display: none;

    @include media(lg) {
      display: block;
      text-align: right;
      color: var(--text-primary);
      grid-column: 1 / 5;
      align-self: center;
    }
  }

  &__links {
    @include grid(3, 3, 3, 3);
    grid-gap: var(--molecule-xs);

    @include media(lg) {
      display: flex;
      justify-content: flex-end;
      grid-column: 5 / 13;
    }
  }
}