@import "../../../styles/mixins";

.b-markets {
  background-color: var(--color-white-100);

  &__container {
    display: flex;
    flex-direction: column;
  }

  &__head {
    justify-self: center;
    text-align: center;
    color: var(--color-grey-900);
    padding-top: gap(8);

    @include media(lg) {
      padding-top: gap(11);
    }
  }

  &__content {
    display: none;

    @include media(lg) {
      @include grid(1, 1, 1, 1, 1);
      gap: var(--molecule-lg);
      padding: var(--block-top-bottom) 0;
    }
  }

  &__list {
    @include grid(3, 3, 3, 4, 4);
    gap: gap(6) gap(8);
  }

  &__button {
    justify-self: center;

    @include media(lg, max) {
      display: none;
    }
  }

  &__slider {
    position: relative;
    overflow: hidden;
  }

  &__slider-inner {
    margin: 0 gap(-4);

    @include media(lg) {
      display: none;
    }
  }

  &__slider-wrapper {
    display: flex;
    padding: gap(8) gap(4);
  }

  &__slider-slide {
    width: calc(100% - 94px);
    height: auto !important;
  }
}
