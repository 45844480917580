@import "../../../styles/mixins";

.b-article-slider {
  $self: &;

  background-color: var(--color-white-100);
  position: relative;
  padding: gap(8) 0;
  overflow: hidden;

  @include media(lg) {
    padding: gap(17) 0;
  }

  &--side-visible-no {
    #{ $self }__slider-inner {
      overflow: visible;

      @include media(lg) {
        grid-column: span 12;
      }
    }

    #{ $self }__slider-container {
      width: auto !important;
      overflow: visible;
    }

    #{ $self }__slider {
      overflow: visible;

      &::before {
        display: none;
      }
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    color: var(--color-grey-900);
    position: relative;
    z-index: 1;
  }

  &__title {
    text-align: center;

    @include media(lg, max) {
      padding-top: gap(8);
    }
  }

  &__content {
    padding-top: gap(8);
    @include grid(1, 1, 12, 12, 12);
    gap: gap(6);

    @include media(lg) {
      padding-top: gap(12);
    }
  }

  &__side {
    display: flex;
    flex-direction: column;
    gap: gap(6);
    grid-column: span 2;

    @include media(lg, max) {
      display: none;
    }
  }

  &__slider-inner {
    position: relative;

    @include media(lg) {
      grid-column: span 10;
    }
  }

  &__slider-container {
    width: 100vw;
    overflow: hidden;

    @include media(lg, max) {
      height: 100% !important;
    }
  }

  &__slider {
    @include media(lg) {
      &::before {
        content: '';
        background: linear-gradient(270deg, #101828 0.43%, rgba(16, 24, 40, 0.00) 89.19%);
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 114px;
        z-index: 2;
      }
    }
  }

  &__slider-slide {
    width: calc(100% - 126px);
    height: auto !important;

    @include media(lg) {
      width: 406px;
    }
  }

  &__slider-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 3;

    @include media(lg, max) {
      display: none;
    }

    &--prev {
      left: gap(10);
    }

    &--next {
      right: gap(10);
    }
  }

  &__wave {
    color: var(--color-grey-900) !important;

    @include media(lg, max) {
      display: none !important;
    }
  }
}
