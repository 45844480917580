@import "../../../styles/mixins";

.search {
  $self: &;
  width: 100%;
  display: flex;
  align-items: center;
  gap: gap(4);
  padding: gap(0.75);
  border-radius: var(--radius-full);
  border: 1px solid var(--border-primary);
  background-color: var(--bg-neutral-light);
  transition: var(--transition-border);

  &:hover {
    border-color: var(--border-primary-hover);
  }

  &:focus-within {
    border-color: var(--border-primary-active);

    #{ $self }__input {
      &::placeholder {
        color: var(--text-primary);
      }
    }
  }

  &__input {
    flex: 1;
    width: 100%;
    border: none;
    outline: none;
    padding: 0 gap(6);
    color: var(--text-primary);
    transition: var(--transition-color);

    &::placeholder {
      color: var(--text-placeholder);
    }

    &:placeholder-shown {
      & ~ #{ $self }__controls {
        & > button[type="reset"] {
          visibility: hidden;
          pointer-events: none;
        }
      }
    }
  }

  &__controls {
    & > button[type="reset"] {
      color: var(--icon-primary);
      background-color: var(--bg-neutral-light);

      &:hover {
        color: var(--icon-primary-hover);
        background-color: var(--bg-neutral-light-hover);
      }


      &:active {
        color: var(--icon-primary-active);
        background-color: var(--bg-neutral-light-active);
      }
    }
  }
}